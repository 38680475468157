import React from 'react'

import CustomToastContainer from '@/components/CustomToastContainer'
import { Packs } from '@/components/dogepad/packs'
import { Collection } from '@/components/doginalpacks/collection'
import { CollectionProvider } from '@/provider/collectionProvider/collectionProvider'

import PageBaseDogepad from '../_baseDogepad'

export const dogepadCollectionSymbols = ['doginal-dogs']

const Dogepad = () => {
  const [isActive, setIsActive] = useState<boolean>(false)

  return (
    <CollectionProvider>
      <PageBaseDogepad>
        <CustomToastContainer />
        {!isActive ? (
          <div className="mt-15">
            <Packs />
          </div>
        ) : (
          <div>
            <Collection
              isActive={isActive}
              revealCollections={dogepadCollectionSymbols}
              emptyMessage="You don't have any Dogepad items yet!"
              containerStyle={{ color: '#FFFFFF' }}
            />
          </div>
        )}
      </PageBaseDogepad>
    </CollectionProvider>
  )
}

export default Dogepad

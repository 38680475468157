import { Skeleton } from 'antd'

const LoadingDoginalCard = () => {
  return (
    <div className="relative h-[380px] w-[320px] border-[2.5px] border-solid rounded-[12px] flex-col p-[12px] shadow-[0px_6px_16px_0px_#0000001A]">
      <Skeleton.Input
        active
        block
        size="small"
        style={{ width: '100%', height: '200px', borderRadius: '6px', marginBottom: '32px' }}
      />
      <div className="h-1.3/3 flex flex-col justify-between pb-[16px]">
        <div className="mx-[-32px]">
          <div className="w-full flex justify-between text-[20px] font-bold">
            <Skeleton.Input active block size="small" style={{ width: '60px', borderRadius: '6px' }} />
            <Skeleton.Input active block size="small" style={{ width: '40px', borderRadius: '6px' }} />
          </div>
        </div>
        <Skeleton.Button
          active
          block
          size="small"
          style={{ display: 'flex', width: '100%', height: '60px', borderRadius: '6px', marginBottom: '16px' }}
        />
      </div>
    </div>
  )
}

export { LoadingDoginalCard }

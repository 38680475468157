import React from "react";
import __pages_import_0__ from "/src/pages/_base.tsx";
import __pages_import_1__ from "/src/pages/_baseChampionsTCG.tsx";
import __pages_import_2__ from "/src/pages/_baseDogepad.tsx";
import __pages_import_3__ from "/src/pages/index.tsx";
import __pages_import_4__ from "/src/pages/explorer/ranking.tsx";
import __pages_import_5__ from "/src/pages/explorer/recently.tsx";
import __pages_import_6__ from "/src/pages/explorer/trending.tsx";
import __pages_import_7__ from "/src/pages/legal/terms.tsx";
import __pages_import_8__ from "/src/pages/service/championsTCG.tsx";
import __pages_import_9__ from "/src/pages/service/dogepad.tsx";
import __pages_import_10__ from "/src/pages/service/doginals.tsx";
import __pages_import_11__ from "/src/pages/service/inscribe.tsx";
import __pages_import_12__ from "/src/pages/service/labradoge-pack-reveal.tsx";
import __pages_import_13__ from "/src/pages/service/labradoge.tsx";
import __pages_import_14__ from "/src/pages/service/re-roll.tsx";
import __pages_import_15__ from "/src/pages/service/tennis-balls.tsx";
import __pages_import_16__ from "/src/pages/service/trade.tsx";
import __pages_import_17__ from "/src/pages/wallet/balances.tsx";

const routes = [{"caseSensitive":false,"path":"_base","element":React.createElement(__pages_import_0__)},{"caseSensitive":false,"path":"_baseChampionsTCG","element":React.createElement(__pages_import_1__)},{"caseSensitive":false,"path":"_baseDogepad","element":React.createElement(__pages_import_2__)},{"caseSensitive":false,"path":"/","element":React.createElement(__pages_import_3__)},{"caseSensitive":false,"path":"explorer","children":[{"caseSensitive":false,"path":"ranking","element":React.createElement(__pages_import_4__)},{"caseSensitive":false,"path":"recently","element":React.createElement(__pages_import_5__)},{"caseSensitive":false,"path":"trending","element":React.createElement(__pages_import_6__)}]},{"caseSensitive":false,"path":"legal","children":[{"caseSensitive":false,"path":"terms","element":React.createElement(__pages_import_7__)}]},{"caseSensitive":false,"path":"service","children":[{"caseSensitive":false,"path":"championsTCG","element":React.createElement(__pages_import_8__)},{"caseSensitive":false,"path":"dogepad","element":React.createElement(__pages_import_9__)},{"caseSensitive":false,"path":"doginals","element":React.createElement(__pages_import_10__)},{"caseSensitive":false,"path":"inscribe","element":React.createElement(__pages_import_11__)},{"caseSensitive":false,"path":"labradoge-pack-reveal","element":React.createElement(__pages_import_12__)},{"caseSensitive":false,"path":"labradoge","element":React.createElement(__pages_import_13__)},{"caseSensitive":false,"path":"re-roll","element":React.createElement(__pages_import_14__)},{"caseSensitive":false,"path":"tennis-balls","element":React.createElement(__pages_import_15__)},{"caseSensitive":false,"path":"trade","element":React.createElement(__pages_import_16__)}]},{"caseSensitive":false,"path":"wallet","children":[{"caseSensitive":false,"path":"balances","element":React.createElement(__pages_import_17__)}]}];

export default routes;
import { SwiperSlide } from 'swiper/react'

import { LandingPageAcademyCard } from '../components/cards/LandingPageAcademyCard'
import { SwiperSlider } from '../components/slider/SwiperSlider'
import { LandingPageTitle } from '../components/title/LandingPageTitle'

const LandingPageAcademy = () => {
  return (
    <div>
      <LandingPageTitle blackText="The" orangeText="Academy" style={{ margin: '72px 0 24px 0' }} />
      <div className="mb-[100px]">
        <SwiperSlider spaceBetween={42}>
          <SwiperSlide>
            <LandingPageAcademyCard
              title="What are doginals & drc20s?"
              category="Learn"
              estimatedTime="6 min read"
              imageUrl="/images/cute-doggo.png"
              link="https://medium.com/doge-labs-drc-20-doginals/what-are-doginals-and-drc20-a-beginners-guide-to-dogecoin-nfts-e502d87420ef"
            />
          </SwiperSlide>
          <SwiperSlide>
            <LandingPageAcademyCard
              title="How to sell drc20s & Doge NFTs"
              category="Tutorial"
              estimatedTime="3 min watch"
              imageUrl="/images/how-to-sell.png"
              link="https://www.youtube.com/watch?v=NWjMJHcXhVE"
            />
          </SwiperSlide>
          <SwiperSlide>
            <LandingPageAcademyCard
              title="What you need to know about Doginals"
              category="Learn"
              estimatedTime="12 min read"
              imageUrl="/images/doginals-knowledge.png"
              link="https://doge-labs.gitbook.io/doginals/the-basics/what-you-need-to-know"
            />
          </SwiperSlide>
          <SwiperSlide>
            <LandingPageAcademyCard
              title="How to mint an NFT on Dogecoin"
              category="Tutorial"
              estimatedTime="2 min watch"
              imageUrl="/images/store-image-on-doge.png"
              link="https://www.youtube.com/watch?v=GpVksqBNJzg"
            />
          </SwiperSlide>
        </SwiperSlider>
      </div>
    </div>
  )
}

export { LandingPageAcademy }

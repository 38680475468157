import PageBase from '../_base'

import '../index.css'

const Terms = () => {
  useEffect(() => {
    window.open('https://terms.drc-20.org', '_blank')
    window.location.href = '/marketplace'
  }, [])

  return null
}

export default Terms

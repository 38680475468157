import { Button } from 'antd'

type LandingPagePrimaryButton = {
  children?: React.ReactNode
  disabled?: boolean
  onClick?: () => void
  styles?: React.CSSProperties
  text?: string
}

const LandingPagePrimaryButton = ({ styles, text, children, disabled = false, onClick }: LandingPagePrimaryButton) => {
  return (
    <Button
      disabled={disabled}
      style={{
        height: '50px',
        border: 'none',
        background: 'linear-gradient(89.93deg, #FA6A01 2.76%, #F38401 55.4%, #ED6401 94.76%)',
        padding: '8px 12px',
        fontWeight: 500,
        fontSize: '16px',
        color: '#FFFFFF',
        ...styles,
      }}
      onClick={onClick}
    >
      {children ?? text ?? null}
    </Button>
  )
}
export { LandingPagePrimaryButton }

import React, { useCallback, useEffect } from 'react'

import { waitUntilTxInMempool } from '@/api'
import { useRevealData } from '@/hooks/useRevealData'
import { BurnInfo, RevealProcessingStatus } from '@/types/labradoge'

import styles from './revealingOverview.module.scss'

export const steps = [
  {
    key: RevealProcessingStatus.MemoryPool,
    label: 'Awaiting confirmation from the Dogecoin Network allowing us to start burning',
    short: 'Waiting for Tx',
  },
  {
    key: RevealProcessingStatus.Burned,
    label: 'Your pack is burned and sent to a designated burn address',
    short: 'Burning Pack',
  },
  {
    key: RevealProcessingStatus.InProgress,
    label: 'Wait to find your Doginal in the currently processing block at burn address',
    short: 'Waiting for Block',
  },
  { key: RevealProcessingStatus.ToBeInscribed, label: 'Your new Doginal is randomly generated', short: 'In Reveal' },
  {
    key: RevealProcessingStatus.Inscribed,
    label: 'Your Labradoge is being added to the collection',
    short: 'Inscribing',
  },
  {
    key: RevealProcessingStatus.Completed,
    label: 'The generated Labradoge is inscribed as an NFT',
    short: 'Inscribed',
  },
  {
    key: RevealProcessingStatus.UploadedToMarketplace,
    label: 'Your revealed Labradoge NFT appears in your collection',
    short: 'Revealed',
  },
  {
    key: RevealProcessingStatus.Failed,
    label: 'Something went wrong. Please contact support.',
    short: 'Error in Reveal',
  },
]

export function getStepByKey(
  key: RevealProcessingStatus
): { key: RevealProcessingStatus; label: string; short: string } | undefined {
  return steps.find((step) => step.key === key)
}

export function RevealingOverview() {
  const { triggerBurnInfoSending, currentBurnInfo, isRevealingError, isRevealing } = useRevealData()
  const estimatedTime = 30

  const isStepCompleted = (stepKey: string, currentStep?: string) => {
    const currentIndex = steps.findIndex((step) => step.key === currentStep)
    const stepIndex = steps.findIndex((step) => step.key === stepKey)
    const lastIndex = steps.length - 1
    return currentIndex !== lastIndex ? stepIndex < currentIndex : true
  }

  const isStepRunning = (stepKey: string, currentStep?: string) => {
    const currentIndex = steps.findIndex((step) => step.key === currentStep)
    const stepIndex = steps.findIndex((step) => step.key === stepKey)
    const lastIndex = steps.length - 1
    return currentIndex !== lastIndex ? stepIndex === currentIndex : false
  }

  const isLastStepCompleted = (stepKey: string, currentStep?: string) => {
    const lastIndex = steps.length - 1
    const currentIndex = steps.findIndex((step) => step.key === currentStep)
    return lastIndex === currentIndex
  }

  const waitForTxInMempool = useCallback(async (burnInfo: BurnInfo): Promise<void> => {
    await waitUntilTxInMempool(burnInfo.txHash)
  }, [])

  useEffect(() => {
    if (currentBurnInfo && currentBurnInfo.burnStatus === RevealProcessingStatus.MemoryPool) {
      waitForTxInMempool(currentBurnInfo).then(async () => {
        // replace currentBurnInfo.burnStatus with Burned
        const burnedBurnInfo = { ...currentBurnInfo, burnStatus: RevealProcessingStatus.Burned }
        await triggerBurnInfoSending(burnedBurnInfo)
      })
    }
  }, [currentBurnInfo, triggerBurnInfoSending, waitForTxInMempool])

  return (
    <>
      <div className={`flex w-full flex-row items-center justify-center`}>
        <div className={`${styles.openingpack}`}>
          {isRevealingError ? (
            <div className="text-sm">
              There was an error in inscribing your labradoge. Please send your Wallet Address, Current Date and Time to
              your support team at Discord.
            </div>
          ) : currentBurnInfo ? (
            <>
              {!isLastStepCompleted(RevealProcessingStatus.UploadedToMarketplace, currentBurnInfo.burnStatus) && (
                <>
                  <div className={styles.openingpackheader}>
                    <h2>Opening Pack</h2>
                    <p>Estimated time: Up to {estimatedTime} minutes.</p>
                    <p>You can safely close this page and check your wallet later for this reveal later.</p>
                    <p>
                      You can also start revealing another{' '}
                      <a href={'/service/labradoge'} rel="noopener noreferrer">
                        Doginal
                      </a>
                    </p>
                  </div>
                  <ul className={`${styles.openingpacksteps}`}>
                    {steps.map((step) => (
                      <li
                        key={step.key}
                        className={`text-left flex items-center ml-5 mb-5 ${styles.step} ${
                          isStepCompleted(step.key, currentBurnInfo.burnStatus) ? styles.completed : ''
                        } ${isStepRunning(step.key, currentBurnInfo.burnStatus) ? styles.running : ''}`}
                      >
                        <span
                          className={`${
                            isStepRunning(step.key, currentBurnInfo.burnStatus)
                              ? styles.stepindicatorrunning
                              : isStepCompleted(step.key, currentBurnInfo.burnStatus)
                              ? styles.stepindicatorcompleted
                              : styles.stepindicatorwaiting
                          }`}
                        />
                        <span className={styles.steplabel}>{step.label}</span>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {isLastStepCompleted(RevealProcessingStatus.UploadedToMarketplace, currentBurnInfo.burnStatus) && (
                <div className={styles.openingpackheader}>
                  <h2>Reveal Completed.</h2>
                  <p>Waiting to load newly revealed Labradoge.</p>
                </div>
              )}
            </>
          ) : isRevealing ? (
            <div>Waiting to receive BurnInfo...</div>
          ) : (
            <div>No burn information available.</div>
          )}
        </div>
      </div>
    </>
  )
}

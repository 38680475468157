import React from 'react'

import InputField from './InputField'

type SliderDelegateProps = {
  inscriptionId: string
  setInscriptionId: (inscriptionId: string) => void
  amount: number | null
  setAmount: (amount: number | null) => void
  recipientAddress: string
  setRecipientAddress: (recipientAddress: string) => void
  walletAddressFromParams?: string
  showAmount?: boolean
}

const SliderDelegate = ({
  setInscriptionId,
  inscriptionId,
  amount,
  setAmount,
  recipientAddress,
  setRecipientAddress,
  walletAddressFromParams,
  showAmount,
}: SliderDelegateProps) => {
  return (
    <>
      <InputField
        label={'Inscription ID'}
        placeholder="Inscription ID"
        style={{
          resize: 'none',
          outline: 'none',
          maxWidth: '480px',
          width: '100%',
          padding: '8px 16px',
        }}
        value={inscriptionId}
        onChange={(e) => {
          setInscriptionId(e.target.value)
        }}
      />
      {showAmount && (
        <InputField
          label="Amount"
          placeholder="Amount"
          style={{
            resize: 'none',
            outline: 'none',
            maxWidth: '480px',
            width: '100%',
            padding: '8px 16px',
          }}
          value={amount}
          onChange={(e) => {
            if (e.target.value === '') {
              setAmount(null)
              return
            }
            if (parseInt(e.target.value) > 100) {
              setAmount(100)
              return
            }
            setAmount(Math.round(e.target.value))
          }}
          type="number"
          step="1"
          max="100"
        />
      )}
      <InputField
        label="Wallet address"
        description={
          <div style={{ maxWidth: '300px' }}>
            We recommend to use the Doge Labs Wallet. Install it{' '}
            <a href={'https://chrome.google.com/webstore/detail/doge-labs-wallet/jiepnaheligkibgcjgjepjfppgbcghmp'}>
              here:
            </a>
            <br /> In case you do not have a wallet address yet, you can create it with it.
          </div>
        }
        placeholder="Recipient wallet address"
        value={recipientAddress}
        onChange={(e: any) => {
          setRecipientAddress(e.target.value)
        }}
        inputStyle={{ marginLeft: '-10px' }}
      />
    </>
  )
}

export default SliderDelegate

import { useState } from 'react'

import { getDogecoinPriceInUsd } from '@/api'

import PageBase from '../_base'
import { LandingPageAcademy as Academy } from './sections/LandingPageAcademy'
import { LandingPageBanner as Banner } from './sections/LandingPageBanner'
import { LandingPageContact as Contact } from './sections/LandingPageContact'
import { LandingPageDogeWalletBanner as WalletBanner } from './sections/LandingPageDogeWalletBanner'
import { LandingPageHotDRC20s as HotDRC20s } from './sections/LandingPageHotDRC20s'
import { LandingPageLatestInscriptions as LatestInscriptions } from './sections/LandingPageLatestInscriptions'
import { LandingPageLearnMore as LearnMore } from './sections/LandingPageLearnMore'
import { LandingPageNewCollections as NewCollections } from './sections/LandingPageNewCollections'
import { LandingPageNewlyDeployed as NewlyDeployed } from './sections/LandingPageNewlyDeployed'
import { LandingPageRecentSales as RecentSales } from './sections/LandingPageRecentSales'
import { LandingPageTable as Table } from './sections/LandingPageTable'

export const cardHoverAnimation =
  'hover:bg-elevation-2 shadow-elevation-1 duration-200 hover:-translate-y-1 hover:shadow-elevation-2'

const LandingPage = () => {
  const [dogePriceInUSD, setDogecoinPriceInUSD] = useState<number>(0.091)

  const fetchDogePriceInUsd = async () => {
    const { res, err } = await getDogecoinPriceInUsd()

    if (err) {
      console.log(err)
      return err
    }

    return setDogecoinPriceInUSD(res!.data.dogePriceInUsd)
  }

  useEffect(() => {
    fetchDogePriceInUsd()
  }, [])

  return (
    <div id="Landingpage">
      <PageBase bottomBanner={<WalletBanner />}>
        <Table dogePriceInUSD={dogePriceInUSD} />
        <HotDRC20s />
        <NewCollections />
        <RecentSales dogePriceInUSD={dogePriceInUSD} />
        <NewlyDeployed />
        <LatestInscriptions />
        <Academy />
        {/* TODO: add when recaptcha BE is ready */}
        {/* <Contact /> */}
        <LearnMore />
      </PageBase>
    </div>
  )
}

export default LandingPage

import { createContext, SetStateAction, useCallback, useEffect, useMemo, useRef } from 'react'
import Slider from 'react-slick'

import {
  fetchBurnInfo,
  fetchDoginals,
  getImageInscriptions,
  sendBurnInfo,
  updateBurnInfo,
  waitUntilTxInMempool,
} from '@/api'
import { useGetLabradogesData } from '@/hooks/useGetLabradogesData'
import { useWallet } from '@/hooks/useWallet'
import { RevealApi, RevealProviderProps } from '@/provider/revealProvider/revealProvider.types'
import { ImageInscriptionResponse } from '@/types/dogeNft'
import { BurnInfo, Labradoge, RevealProcessingStatus } from '@/types/labradoge'

const defaultContext: RevealApi = {
  setCollections: ([]) => undefined,
  setSelectedLabradogeRevealAmount: (int) => undefined,
  selectedLabradogeRevealAmount: 0,
  availableLabradogeAmount: 0,
  selectedLabradoge: undefined,
  labradoges: [],
  isRevealing: false,
  isKeepingLabradoge: false,
  isRevealButtonDisabled: false,
  isModalOpen: false,
  revealedLabradoges: null,
  slider: null,
  onClickSelect: () => undefined,
  onOpenDetailModal: () => undefined,
  onCloseDetailModal: () => undefined,
  setIsKeepingLabradoge: () => undefined,
  handleLabradogeSelection: () => undefined,
  onReveal: async () => undefined,
  currentBurnInfo: undefined,
  isRevealingError: false,
  triggerBurnInfoSending: async () => undefined,
}
export const afterRevealCollectionNames = ['labradoges', 'labradoges-first-edition']
export const RevealContext = createContext(defaultContext)
const NULL_ADDRESS: string = 'DDogepartyxxxxxxxxxxxxxxxxxxw1dfzr'

export function RevealProvider({ children }: RevealProviderProps) {
  const [selectedLabradogeRevealAmount, setSelectedLabradogeRevealAmount] = useState(1)
  const [availableLabradogeAmount, setAvailableLabradogeAmount] = useState(0)
  const [selectedLabradoge, setSelectedLabradoge] = useState<Labradoge | undefined>()
  const [allDoginalsFromWallet, setAllDoginalsFromWallet] = useState<null | Labradoge[]>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isRevealing, setIsRevealing] = useState(false)
  const [isRevealingError, setIsRevealingError] = useState(false)
  const [collections, setCollections] = useState<null | string[]>(null)
  const [labradoges, setLabradoges] = useState<null | Labradoge[]>(null)
  const [isKeepingLabradoge, setIsKeepingLabradoge] = useState(false)
  const [revealedLabradoges, setRevealedLabradoges] = useState<Labradoge[] | null>(null)
  const [shouldFetchNewLabradoges, setShouldFetchNewLabradoges] = useState(false)
  const [currentBurnInfo, setCurrentBurnInfo] = useState<BurnInfo | undefined | null>()

  const slider = useRef<Slider>(null)
  const fetchTimeoutRef = useRef<NodeJS.Timeout | number | null>(null)

  const { address, connected, sendInscription } = useWallet()
  const { doginals, error, loading, complete } = useGetLabradogesData(address, connected, collections)

  const fetchData = useCallback(async (): Promise<ImageInscriptionResponse[] | undefined> => {
    if (connected && address) {
      return (await getImageInscriptions({ address, cursor: 0, size: 100 })).list
    }
  }, [connected, address])

  const triggerBurnInfoSending = useCallback(async (burnInfo: BurnInfo): Promise<void> => {
    await sendBurnInfo(burnInfo)
    setCurrentBurnInfo(burnInfo)
  }, [])

  // Function to fetch and store all Doginals from the wallet
  const fetchAndStoreAllDoginals = useCallback(async () => {
    const collectionInformation = await fetchData()
    if (!collectionInformation) {
      setIsRevealing(false)
      return
    }
    const allDoginals = await fetchDoginals(collectionInformation, null)
    setAllDoginalsFromWallet(allDoginals)
  }, [fetchData])

  const fetchCollectionInformation = useCallback(async (): Promise<null | ImageInscriptionResponse[]> => {
    const collectionInformation = await fetchData()
    if (!collectionInformation) {
      return null
    }

    return collectionInformation
  }, [fetchData])

  const getBurnInfo = useCallback(async (): Promise<void> => {
    if (connected && address && currentBurnInfo) {
      const newBurnInfo = await fetchBurnInfo(currentBurnInfo.txHash)
      setCurrentBurnInfo(newBurnInfo)
    }
  }, [connected, address, currentBurnInfo])

  // Function to fetch Doginals and find new ones after reveal
  const fetchAndCompareDoginals = useCallback(
    async (collectionInformation: ImageInscriptionResponse[]): Promise<null | Labradoge[]> => {
      const newlyFetchedLabradogesFromWallets = await fetchDoginals(collectionInformation, afterRevealCollectionNames)

      let newDoginals = null
      if (newlyFetchedLabradogesFromWallets && newlyFetchedLabradogesFromWallets.length > 0) {
        // Compare the new Doginals with the initial ones to find truly new Doginals
        newDoginals = newlyFetchedLabradogesFromWallets.filter(
          (newDoginal) =>
            allDoginalsFromWallet &&
            !allDoginalsFromWallet.some((initialDoginal) => initialDoginal.id === newDoginal.id)
        )
      }

      return newDoginals
    },
    [allDoginalsFromWallet]
  )

  useEffect(() => {
    let isSubscribed = true // This flag will prevent updating state if the component is unmounted

    // triggered in onReveal by shouldFetchNewLabradoges setting to true
    const fetchLoop = async () => {
      if (shouldFetchNewLabradoges && allDoginalsFromWallet) {
        try {
          const collectionInformation = await fetchCollectionInformation()
          if (!collectionInformation) {
            return
          }

          await getBurnInfo()
          const newLabradoges = await fetchAndCompareDoginals(collectionInformation)

          if (newLabradoges && newLabradoges.length > 0) {
            setRevealedLabradoges(newLabradoges)
            setShouldFetchNewLabradoges(false) // Stop the fetch loop
            setIsRevealing(false)
          } else {
            // No new Labradoges found, set up the next fetch in the loop
            fetchTimeoutRef.current = setTimeout(fetchLoop, 5000) // Fetch again after 5 seconds
          }
        } catch (e) {
          console.error(e)
          console.warn('onReveal-restart Loop')
          fetchTimeoutRef.current = setTimeout(fetchLoop, 5000) // Fetch again after 5 seconds
        }
      }
    }

    if (isSubscribed && shouldFetchNewLabradoges) {
      fetchLoop().then()
    }

    return () => {
      isSubscribed = false
      if (fetchTimeoutRef.current !== null) {
        clearTimeout(fetchTimeoutRef.current)
      }
    }
  }, [
    shouldFetchNewLabradoges,
    fetchData,
    collections,
    labradoges,
    allDoginalsFromWallet,
    fetchCollectionInformation,
    fetchAndCompareDoginals,
    getBurnInfo,
  ])

  useEffect(() => {
    if (complete && doginals) {
      setLabradoges(doginals)
    }

    if (selectedLabradoge && complete && doginals) {
      const filteredLabradoges = doginals?.filter(
        (doginal) => doginal.collectionSymbol === selectedLabradoge.collectionSymbol
      )
      setLabradoges(filteredLabradoges)
      setAvailableLabradogeAmount(filteredLabradoges.length)
    }
  }, [doginals, setLabradoges, complete, selectedLabradoge])

  const isRevealButtonDisabled = useMemo(() => {
    return !selectedLabradoge
  }, [selectedLabradoge])

  const waitForTxInMempool = useCallback(async (burnInfo: BurnInfo): Promise<void> => {
    await waitUntilTxInMempool(burnInfo.txHash)
  }, [])

  const onReveal = useCallback(async () => {
    if (!selectedLabradoge) {
      return
    }

    try {
      const { collectionSymbol, inscriptionId } = selectedLabradoge
      const txHash = await sendInscription(NULL_ADDRESS, inscriptionId.toString())
      console.log('txHash', txHash)
      const burnInfo: BurnInfo = {
        txHash,
        address,
        inscriptionId: inscriptionId.toString(),
        collectionSymbol,
        burnStatus: RevealProcessingStatus.MemoryPool,
      }

      // // @todo: not needed if the revealOverview.tsx is used
      await waitForTxInMempool(burnInfo)
      await sendBurnInfo(burnInfo)

      setCurrentBurnInfo(burnInfo)
      // // fake burn status after 60 seconds
      setTimeout(async () => {
        const burnedBurnInfo = { ...burnInfo, burnStatus: RevealProcessingStatus.Burned }
        setCurrentBurnInfo(burnedBurnInfo)
        await updateBurnInfo(burnedBurnInfo)
      }, 60000)
      setIsRevealing(true)
    } catch (error) {
      console.log('sendInscription Error', error)
      setIsRevealingError(true)
      setIsRevealing(false)
    }
    // }
  }, [selectedLabradoge, sendInscription, address, waitForTxInMempool])

  const handleLabradogeSelection = useCallback((labradoge: Labradoge) => {
    setSelectedLabradoge(labradoge)
  }, [])

  const onClickSelect = useCallback(() => {
    slider?.current?.slickNext()
  }, [])

  const onOpenDetailModal = useCallback((labradoge: Labradoge) => {
    setSelectedLabradoge(labradoge)
    setIsModalOpen(true)
  }, [])

  const onCloseDetailModal = useCallback(() => {
    setSelectedLabradoge(undefined)
    setIsModalOpen(false)
  }, [])

  const api: RevealApi = useMemo(
    () => ({
      selectedLabradogeRevealAmount,
      setSelectedLabradogeRevealAmount,
      availableLabradogeAmount,
      labradoges,
      isModalOpen,
      isKeepingLabradoge,
      isRevealing,
      isRevealButtonDisabled,
      setIsKeepingLabradoge,
      revealedLabradoges,
      onClickSelect,
      onOpenDetailModal,
      onCloseDetailModal,
      onReveal,
      selectedLabradoge,
      slider,
      handleLabradogeSelection,
      setCollections,
      currentBurnInfo,
      isRevealingError,
      triggerBurnInfoSending,
    }),
    [
      selectedLabradogeRevealAmount,
      availableLabradogeAmount,
      labradoges,
      isModalOpen,
      isKeepingLabradoge,
      isRevealing,
      isRevealButtonDisabled,
      revealedLabradoges,
      onClickSelect,
      onOpenDetailModal,
      onCloseDetailModal,
      onReveal,
      selectedLabradoge,
      handleLabradogeSelection,
      currentBurnInfo,
      isRevealingError,
      triggerBurnInfoSending,
    ]
  )

  return <RevealContext.Provider value={api}>{children}</RevealContext.Provider>
}

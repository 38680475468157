import React from 'react'

import { getDoginalOffer, getPsdtFromOfferId, waitUntilTxInMempool } from '@/api'
import useToast from '@/hooks/useToast'
import Sentry from '@/main'
import { Doginal, DoginalOffer } from '@/types/dogeNft'
import { ONE_DOGE_IN_SHIBES } from '@/utils/constants'
import { useWalletContext } from '@/WalletContext'

type DoginalBuyProps = {
  offer: DoginalOffer
  dogecoinPriceInUsd: number
  setShowTxModalVisible: (value: boolean) => void
  setTxHash: (value: string) => void
}
const DoginalBuy = ({ offer, dogecoinPriceInUsd, setShowTxModalVisible, setTxHash }: DoginalBuyProps) => {
  const { balance, address } = useWalletContext()
  const toast = useToast()
  const [purchaseInProgress, setPurchaseInProgress] = React.useState(false)

  const buyDoginalOfferWithRetry = async (offerId: string, signedPsdtHex: string, address: string, retries = 100) => {
    setPurchaseInProgress(true)
    let successfullPurchaseTx = null
    let attempt = 1

    while (!successfullPurchaseTx && attempt <= retries) {
      attempt++
      try {
        const data = await (window as any).dogeLabs.buyDoginalOffer(offerId, signedPsdtHex, address)

        if (!data || data.status === 500) {
          // If the first attempt fails, try again after timeout
          if (attempt > retries) {
            toast.showErrorToast('Unable to purchase: Please try again later')
            Sentry.captureMessage('Unable to purchase: Please try again later', {
              extra: {
                address: address,
                balance: balance,
                offer: offer,
                signedPsdtHex,
              },
            })
            setPurchaseInProgress(false)
            return
          }
          await new Promise((resolve) => setTimeout(resolve, 3000))
        } else {
          successfullPurchaseTx = data.txHash
        }
      } catch (e) {
        await new Promise((resolve) => setTimeout(resolve, 3000))
      }
    }

    if (successfullPurchaseTx) {
      setPurchaseInProgress(false)
      setTxHash(successfullPurchaseTx)
      setShowTxModalVisible(true)
    }
  }

  const handleBuyClick = async (doginal: DoginalOffer) => {
    if (balance.total <= Number(doginal.price)) {
      toast.showErrorToast('Unable to purchase: Insufficient balance')
      return
    }

    try {
      const { offer } = await getDoginalOffer(doginal.inscriptionId)
      const sellerPsdtHex = await getPsdtFromOfferId(offer.offerId, 'doginals')

      // Create 3 outputs required for purchasing the offer
      const unsignedBuyerPrePurchaseTx = await (window as any).dogeLabs.createPurchaseOfferInputs(sellerPsdtHex)
      const signedBuyerPrePurchaseTx = await (window as any).dogeLabs.signPsbt(unsignedBuyerPrePurchaseTx)

      let pushPsbtTimeoutRejectionTimer: any
      const pushPsbtResult = (window as any).dogeLabs.pushPsbt(signedBuyerPrePurchaseTx, [], true)
      const pushPsbtTimeoutRejection = new Promise(
        (_r, rej) => (pushPsbtTimeoutRejectionTimer = setTimeout(() => rej('pushPsbtTimeoutRejection'), 30000))
      )

      try {
        const { txHash, rawTx } = await Promise.race([pushPsbtResult, pushPsbtTimeoutRejection]).finally(() =>
          clearTimeout(pushPsbtTimeoutRejectionTimer)
        )

        await waitUntilTxInMempool(txHash)
        const buyerPsdtHex = await (window as any).dogeLabs.createBuyerPsdt(sellerPsdtHex, rawTx)

        const signedPsdtHex = await (window as any).dogeLabs.signPsbt(buyerPsdtHex)
        await buyDoginalOfferWithRetry(offer.offerId, signedPsdtHex, address)
      } catch (e) {
        // e.g. timeout for pushPsbt
        toast.showErrorToast(`Unable to make a purchase. Reset Wallet State and try again.`, { autoClose: 5000 })
      }
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          address: address,
          balance: balance,
          offer: offer,
          price: dogecoinPriceInUsd,
        },
      })
      if (e instanceof Error) {
        toast.showErrorToast(`Unable to make a purchase: ${e.message}`, { autoClose: 5000 })
      }
    }
  }
  return (
    <div className="flex flex-col items-start border-2 border-account-page-background bg-white rounded-lg p-4 my-2">
      <p className="text-xs ml-0.5">Price</p>
      <p className="font-bold text-lg">
        {' '}
        {(offer.price / ONE_DOGE_IN_SHIBES).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}{' '}
        DOGE
      </p>
      <span className="text-xxs text-gray-500 tracking-tighter ml-0.5">
        ~$
        {((offer.price / ONE_DOGE_IN_SHIBES) * dogecoinPriceInUsd).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })}
      </span>
      <button
        className={`${
          address ? 'bg-selected-color' : 'bg-gray-200'
        } rounded-xl text-white text-base font-bold p-2 w-full mt-2`}
        onClick={async () => await handleBuyClick(offer)}
        disabled={!address || purchaseInProgress}
      >
        {address ? (purchaseInProgress ? 'Waiting for confirmation' : 'Buy now') : 'Connect Wallet'}
      </button>
      <span className="pt-4 text-left">
        By clicking “Buy now”, you confirm the accuracy of the input data and agree to the{' '}
        <a href="https://terms.drc-20.org" target="_blank" rel="noreferrer" className="underline">
          Terms of Use
        </a>
        .
      </span>
    </div>
  )
}

export default DoginalBuy

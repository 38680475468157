import { Popover } from 'antd'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router'

import { ONE_DOGE_IN_SHIBES, TrustLevel } from '@/utils/constants'

import SupplyProgressBar from './SupplyProgressBar'

const Drc20TrendingTableRow = ({
  index,
  tick,
  price,
  volume,
  percentPriceChange,
  // transactions,
  holders,
  currentSupply,
  maxSupply,
  dogePrice,
  marketplaceVerified,
}: {
  index: any
  tick: string
  price: number
  volume: number
  percentPriceChange?: number
  // transactions: number
  holders: number
  currentSupply: number
  maxSupply: number
  dogePrice: number
  marketplaceVerified?: boolean
}) => {
  const handleClick = (tick: string) => {
    window.open(`https://doge-labs.com/#/drc20/${tick}`, '_blank')
  }

  const handleTextColor = (percentPriceChange: number) => {
    return percentPriceChange < 0 ? 'text-[#E82C44]' : 'text-[#23D581]'
  }

  return (
    <>
      <tr className="text-xs w-full">
        <td className="py-5 text-left sticky left-0 marketplace-table-cell-shadow-right xxs:bg-similar-to-bg-color tableScrollBreakpoint:bg-inherit">
          <span className="flex items-center">
            <span className="pr-4 w-8 text-center">{index}</span>
            <a onClick={() => handleClick(tick)} className="flex items-center gap-2">
              <img
                className="w-7 object-cover rounded-2xl"
                src={`https://drc-20-icons.dogeord.io/${tick.toLowerCase()}.png`}
                alt="Drc20"
                onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                  (e.currentTarget.src = '/ticks/noicon.png')
                }
              />
              <span className="ml-1">{tick}</span>
              {marketplaceVerified && (
                <Popover content={<div>Verified</div>} trigger="hover" placement="right">
                  <img
                    src="/images/verifyIcon.png"
                    alt="Verified"
                    style={{ marginLeft: '5px', height: '15px', width: '16px' }}
                  />
                </Popover>
              )}
            </a>
          </span>
        </td>
        {/* <td className="py-5 text-left sticky table-cell-shadow-right left-6 bg-default-background">
          <a onClick={() => handleClick(tick)} className="inline-flex items-center gap-2">
            <img
              className="w-7 object-cover rounded-2xl"
              src={`https://drc-20-icons.dogeord.io/${tick.toLowerCase()}.png`}
              alt="Drc20"
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                (e.currentTarget.src = '/ticks/noicon.png')
              }
            />{' '}
            {tick}
            {trustLevel === TrustLevel.TRUSTWORTHY && (
              <img src={'/images/verifyIcon.png'} alt="verified" className="h-3 w-3" />
            )}
            {trustLevel === TrustLevel.UNTRUSTWORTHY && (
              <img src={'/images/dangerIcon.png'} alt="danger" className="h-3 w-3" />
            )}
          </a>
        </td> */}
        <td className="py-5 text-left pl-5">
          <div className="flex flex-col">
            <span>
              {(price / ONE_DOGE_IN_SHIBES).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              DOGE
            </span>
            <span className="text-slate-500 text-xxxs">
              $
              {(dogePrice * (price / ONE_DOGE_IN_SHIBES)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        </td>
        <td className="py-5 text-left">
          <div className="flex flex-col whitespace-nowrap">
            <span>
              {(volume / ONE_DOGE_IN_SHIBES).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              DOGE
            </span>
            <span className="text-slate-500 text-xxxs">
              $
              {(dogePrice * (volume / ONE_DOGE_IN_SHIBES)).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        </td>
        {typeof percentPriceChange === 'number' ? (
          <td className={`${handleTextColor(percentPriceChange)} py-5 text-left`}>
            {percentPriceChange > 0 ? '+' : ''}
            {percentPriceChange.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            %
          </td>
        ) : (
          <td className="py-5 text-left">0%</td>
        )}
        {/* <td className="py-5 text-left">{transactions}</td> */}
        <td className="py-5 text-left">
          <div className="flex flex-col">
            <span>
              $
              {(dogePrice * (price / ONE_DOGE_IN_SHIBES) * currentSupply).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        </td>
        <td className="py-5 text-left">{holders}</td>
        <td className="py-5 text-left">
          <SupplyProgressBar currentSupply={currentSupply} maxSupply={maxSupply} />
        </td>
      </tr>
    </>
  )
}

export default Drc20TrendingTableRow

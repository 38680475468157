import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { getStepByKey } from '@/components/doginalpacks/reveal/revealingOverview/revealingOverview'
import { OverviewCard } from '@/components/labradoge'
import { LoadingView } from '@/components/labradoge/loadingView/loadingView'
import WalletConnect from '@/components/WalletConnect'
import { useCollectionData } from '@/hooks/useCollectionData'
import { Labradoge, RevealProcessingStatus } from '@/types/labradoge'
import { useWalletContext } from '@/WalletContext'

export interface CollectionProps {
  isActive: boolean
  revealCollections?: string[]
  emptyMessage?: string
  containerStyle?: any
}

// const revealCollectionNames = ['lab-pack', 'ballz', 'investor-pack', 'unleash-doginal']
export const revealCollectionNames = ['lab-pack', 'investor-pack', 'unleash-doginal']

export function Collection({
  isActive,
  revealCollections,
  emptyMessage = "You don't have any Labradoge to reveal or to show yet.",
  containerStyle,
}: CollectionProps) {
  const { connected } = useWalletContext()
  const navigator = useNavigate()
  const {
    setCollections,
    doginals,
    burnedDoginals,
    revealedDoginals,
    collectionLoadingStatus,
    burnedCollectionLoadingStatus,
    revealedCollectionLoadingStatus,
  } = useCollectionData()

  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const [isCompleted, setIsCompleted] = useState<Boolean>(false)
  const [hasDoginals, setHasDoginals] = useState<Boolean>(false)
  const [revealableDoginals, setRevealableDoginals] = useState<Labradoge[] | null>(null)

  useEffect(() => {
    if (connected) {
      setCollections(revealCollections || revealCollectionNames)
    }
  }, [connected, navigator, revealCollections, setCollections])

  useEffect(() => {
    if (
      collectionLoadingStatus?.loading ||
      burnedCollectionLoadingStatus?.loading ||
      revealedCollectionLoadingStatus?.loading ||
      !collectionLoadingStatus?.completed ||
      !burnedCollectionLoadingStatus?.completed ||
      !revealedCollectionLoadingStatus?.completed
    ) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [connected, collectionLoadingStatus, burnedCollectionLoadingStatus, revealedCollectionLoadingStatus])

  useEffect(() => {
    if (
      collectionLoadingStatus?.completed &&
      burnedCollectionLoadingStatus?.completed &&
      revealedCollectionLoadingStatus?.completed
    ) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }
  }, [connected, collectionLoadingStatus, burnedCollectionLoadingStatus, revealedCollectionLoadingStatus])

  useEffect(() => {
    if (doginals?.length === 0 && burnedDoginals?.length === 0 && revealedDoginals?.length === 0) {
      setHasDoginals(false)
    } else {
      setHasDoginals(true)
    }
  }, [doginals, burnedDoginals, revealedDoginals])

  useEffect(() => {
    // filter those doginals with inscriptionId from burnedDoginals, where burnedDoginals.burnInfo.burnStatus is not null
    const activeBurnStatusInscriptionIds = burnedDoginals
      .filter((burnedDoginal) => {
        return burnedDoginal.burnInfo?.burnStatus !== null
      })
      .map((burnedDoginal) => String(burnedDoginal.inscriptionId))

    // filter those doginals with inscriptionId from doginals, where doginals.burnInfo.burnStatus is not null
    const revealableDoginals = doginals.filter((doginal) => {
      return doginal.inscriptionId && !activeBurnStatusInscriptionIds.includes(String(doginal.inscriptionId))
    })
    setRevealableDoginals(revealableDoginals)
  }, [doginals, burnedDoginals])

  return (
    <div
      className="grid gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 justify-center items-center"
      style={containerStyle}
    >
      {!connected && (
        <div className="col-span-full mt-10 flex justify-center items-center gap-6">
          <WalletConnect />
        </div>
      )}
      {connected && isCompleted && !hasDoginals && (
        <div className="col-span-full mt-10 flex justify-center flex-col gap-6">
          <p className="text-xl font-bold">{emptyMessage}</p>
          <p className="text-sm font-bold">
            <a href={'/marketplace/doginals/investor-pack'} target={'_self'}>
              Get some here
            </a>
          </p>
        </div>
      )}
      {connected && isCompleted && burnedDoginals && burnedDoginals.length > 0 && (
        <div className="col-span-full mt-10 flex justify-center flex-col gap-6">
          <p className="text-xl font-bold">
            Congrats! Your pack is now being revealed. This process could take up to 60 minutes.
          </p>
        </div>
      )}
      {connected && isLoading && (
        <div
          className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-50"
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
        >
          <div className="h-[3rem] w-[3rem] text-gray-600 fill-amber-500">
            <LoadingView />
          </div>
        </div>
      )}
      {connected &&
        isCompleted &&
        burnedDoginals &&
        burnedDoginals.length > 0 &&
        burnedDoginals.map((burnedDoginal, index) => (
          <div className="flex flex-col mt-10" key={`burned-${burnedDoginal.id}-${index}-container`}>
            <OverviewCard
              key={`burned-${burnedDoginal.id}-${index}-card`}
              cleanHeadline={`${burnedDoginal.name}`}
              subHeadline={`#${burnedDoginal.id}`}
              imageSrc={burnedDoginal.image}
              isBig={false}
              buttonConfig={[
                {
                  label: burnedDoginal.burnInfo
                    ? (burnedDoginal.burnInfo.burnStatus && getStepByKey(burnedDoginal.burnInfo.burnStatus)?.short) ||
                      RevealProcessingStatus.InProgress
                    : 'Burned',
                  isDisabled: true,
                  onClick: () => {},
                },
              ]}
              onHoverButtonClick={() => {}}
              onHoverButtonLabel={{ label: 'Details', disabled: true }}
            />
          </div>
        ))}
      {connected &&
        isCompleted &&
        revealableDoginals &&
        revealableDoginals.length > 0 &&
        revealableDoginals.map((doginal, index) => (
          <div className="flex flex-col mt-10" key={`${doginal.id}-${index}-container`}>
            <OverviewCard
              key={`${doginal.id}-${index}-card`}
              cleanHeadline={`${doginal.name}`}
              subHeadline={`#${doginal.id}`}
              imageSrc={doginal.image}
              isBig={false}
              buttonConfig={[
                {
                  label: doginal.burnInfo
                    ? (doginal.burnInfo.burnStatus && getStepByKey(doginal.burnInfo.burnStatus)?.short) ||
                      RevealProcessingStatus.InProgress
                    : 'Reveal',
                  isDisabled: doginal.burnInfo !== undefined && doginal.burnInfo !== null,
                  onClick: () => {
                    if (!doginal.burnInfo) {
                      doginal.inscriptionId && navigator(`/labradoges/reveal/${doginal.inscriptionId}`)
                    }
                  },
                },
              ]}
              onHoverButtonClick={() => {}}
              onHoverButtonLabel={{ label: 'Details', disabled: true }}
            />
          </div>
        ))}
      {connected &&
        isCompleted &&
        revealedDoginals &&
        revealedDoginals.length > 0 &&
        revealedDoginals.map((revealedDoginal, index) => (
          <div className="flex flex-col mt-10" key={`burned-${revealedDoginal.id}-${index}-container`}>
            <OverviewCard
              key={`burned-${revealedDoginal.id}-${index}-card`}
              cleanHeadline={`${revealedDoginal.name}`}
              subHeadline={`#${revealedDoginal.id}`}
              imageSrc={revealedDoginal.image}
              isBig={false}
              buttonConfig={[
                {
                  label: 'Revealed',
                  isDisabled: true,
                  onClick: () => {},
                },
              ]}
              onHoverButtonClick={() => {}}
              onHoverButtonLabel={{ label: 'Details', disabled: true }}
            />
          </div>
        ))}
    </div>
  )
}

import { useEffect, useState } from 'react'

import { fetchRevealedDoginalsByAddress } from '@/api'
import { Labradoge } from '@/types/labradoge'

export const useGetRevealedDoginalsData = (address: string, connected: boolean) => {
  const [revealedDoginals, setRevealedDoginals] = useState<Labradoge[]>([])
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [error, setError] = useState<Error | unknown | null>(null)

  useEffect(() => {
    if (connected && address) {
      const fetchRevealedDoginalInformation = async () => {
        try {
          setLoading(true)
          const revealedDoginals = await fetchRevealedDoginalsByAddress(address)
          setRevealedDoginals(revealedDoginals as Labradoge[])
          setComplete(true)
        } catch (err) {
          setError(err)
        } finally {
          setLoading(false)
        }
      }

      // Call the function immediately
      fetchRevealedDoginalInformation().then()

      // Then set up the interval to call it every 10 seconds
      const intervalId = setInterval(fetchRevealedDoginalInformation, 30000)

      // Clear the interval when the component unmounts or dependencies change
      return () => clearInterval(intervalId)
    }
  }, [address, connected])

  return { revealedDoginals, loading, complete, error }
}

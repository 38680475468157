type LandingPageAcademyCardProps = {
  title: string
  category: string
  estimatedTime: string
  imageUrl: string
  link: string
}

const LandingPageAcademyCard = ({ title, category, estimatedTime, imageUrl, link }: LandingPageAcademyCardProps) => {
  const handleClick = () => {
    window.open(link, '_blank')
  }
  return (
    <div
      className="w-[252px] md:w-[280px] max-w-[300px] h-[320px] rounded-[15px] shadow-[0px_6px_16px_0px_#0000001A] ml-[12px] mb-[20px] p-[8px] flex flex-col cursor-pointer"
      onClick={handleClick}
    >
      <img src={imageUrl} alt="academy" className="h-[75%] w-full rounded-[15px] object-cover" />

      <div className="h-[25%] w-full flex flex-col justify-evenly px-[8px]">
        <div className="w-full flex justify-between text-[14px] color-[#FFB627]">
          <span>{category}</span>
          <span>{estimatedTime}</span>
        </div>
        <h3 className="inline-block text-left text-[18px] font-semibold">{title}</h3>
      </div>
    </div>
  )
}

export { LandingPageAcademyCard }
export type { LandingPageAcademyCardProps }

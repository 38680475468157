import { Segmented } from 'antd'
import { SegmentedValue } from 'antd/lib/segmented'

type LandingPageSortingPillProps = {
  options: string[]
  defaultValue: string
  width?: string
  onChange: (x: SegmentedValue) => void
}

const LandingPageSortingPill = ({ options, defaultValue, width, onChange }: LandingPageSortingPillProps) => {
  return (
    <Segmented
      options={options}
      defaultValue={defaultValue}
      onChange={onChange}
      size="large"
      style={{ width: width ?? 'fit-content', fontWeight: 600, background: '#F2F2F2' }}
      className="text-xs md:text-[16px]"
      block
    />
  )
}

export { LandingPageSortingPill }

import axios from 'axios'

import Sentry from '@/main'
import { SortBy } from '@/pages/landingpage/sections/LandingPageHotDRC20s'
import {
  Doginal,
  DoginalBalanceQuery,
  DoginalOffer,
  DoginalsCollection,
  DoginalsCollectionData,
  DoginalsCollectionDto,
  ImageInscriptionResponse,
} from '@/types/dogeNft'
import { Drc20Data, Drc20Offer } from '@/types/drc20'
import { BurnInfo, CollectionInfo, Labradoge } from '@/types/labradoge'
import { MINER_FEE } from '@/utils/constants'

import { executeAsync } from './wrappers'

const DRC20_API = import.meta.env.VITE_DRC20_API || 'https://d20-api2.dogeord.io/'
const WALLET_API = import.meta.env.VITE_WALLET_API || 'https://wallet-api.dogeord.io/'
const UTXO_SERVICE_API = import.meta.env.VITE_UTXO_SERVICE_API || 'https://unspent.dogeord.io/'
const MARKETPLACE_BACKEND_API = import.meta.env.VITE_MARKETPLACE_BACKEND_API || 'https://marketplace-api.dogeord.io/'

type ProtocolMessage = {
  p: string
  op: string
  tick: string
  amt: string
}

type D20Transaction = {
  tick: string
  protocolMessage: ProtocolMessage
  tx: string
  receiver: string
  sender: string
  inscriptionId: string
}

type PendingBalanceData = {
  d20Transactions: D20Transaction[]
  balance: string
}

export type PendingBalanceDatas = {
  [tick: string]: PendingBalanceData
}

type UserAccount = {
  address: string
  balances: PendingBalanceDatas
}
export const pendingD20Balances = async (address: string): Promise<UserAccount> => {
  try {
    const api = axios.create({
      baseURL: WALLET_API,
    })
    const { data }: { data: UserAccount } = await api.get(`pending-balance/${address}`)
    return data
  } catch (err) {
    console.log('error fetching pending d20 balances', err)
    return {
      address,
      balances: {},
    }
  }
}

export const resolveListingPrice = async (inscriptionId: string) => {
  const { data } = await axios.get(MARKETPLACE_BACKEND_API + 'offer/drc20/', {
    params: {
      inscriptionId: inscriptionId,
    },
  })

  return data.offer ? data.offer.price : 0
}

type GetImageInscriptions = {
  address: string
  cursor: number
  size: number
}

export type InscriptionData = {
  inscriptionNumber: number
  inscriptionId: string
  address: string
  outputValue: number
  preview: string
  content: string
  contentLength: number
  contentType: string
  contentBody: string
  timestamp: number
  genesisTransaction: string
  location: string
  output: string
  offset: number
}

type GetImageInscriptionsResponse = {
  status: string
  message: string
  result: {
    list: Array<InscriptionData>
    total: number
  }
}

export const getImageInscriptions = async ({ address, cursor, size }: GetImageInscriptions) => {
  try {
    const { data } = await axios.get<GetImageInscriptionsResponse>(WALLET_API + 'v2/address/inscriptions/', {
      params: {
        address,
        cursor,
        size,
      },
    })

    return data.result
  } catch (err) {
    console.log('error fetching image inscriptions', err)
    return { list: [], total: 0 }
  }
}

export const getDrc20Balance = async (address: string) => {
  const { data } = await axios.get(DRC20_API + 'balances/' + address)

  return data.balanceData ? data.balanceData : []
}

export const postRefreshInscriptions = async (address: string) => {
  const { data } = await axios.post(UTXO_SERVICE_API + 'api/v1/address/refresh', {
    address,
  })

  return data
}

export const getDrc20TransferInscriptions = async (address: string, tick: string) => {
  const { data } = await axios.get(WALLET_API + 'v2/brc20/transferable-list', {
    params: {
      address,
      ticker: tick,
      cursor: 0,
      size: 10000,
    },
  })

  if (data.status === '1' && data.result && data.result.list) {
    return data.result.list
  }

  return []
}

export type GetDrc20List = { list: Array<Drc20Data>; total: number; DOGEprice: number }
export type GetEnhancedDrc20List = { data: Array<Drc20Data> }
export type GetDrc20Data = { drc20Data: Drc20Data }

export const getDrc20List = async (
  offset: number,
  limit: number,
  filterByTick?: string,
  sortOrder: 'asc' | 'desc' = 'desc',
  sortParam: 'volume' | 'price' = 'volume'
) => {
  return await executeAsync(
    async () =>
      await axios.get<GetDrc20List>(`${MARKETPLACE_BACKEND_API}drc20/list`, {
        params: {
          offset,
          limit,
          filterByTick,
          sortOrder,
          sortParam,
        },
      })
  )
}

export const getFeaturedDrc20List = async (
  offset: number,
  limit: number,
  filterByTick?: string,
  sortOrder: 'asc' | 'desc' = 'desc',
  sortParam: 'volume' | 'price' = 'volume'
) => {
  return await executeAsync(
    async () =>
      await axios.get<GetDrc20List>(`${MARKETPLACE_BACKEND_API}drc20/list/featured`, {
        params: {
          offset,
          limit,
          filterByTick,
          sortOrder,
          sortParam,
        },
      })
  )
}

export const getEnhancedDrc20List = async (
  offset: number,
  limit: number,
  sortOrder: 'asc' | 'desc' = 'desc',
  sortParam: 'volume' | 'createdAt' | 'price' = 'volume'
) => {
  return await executeAsync(
    async () =>
      await axios.get(`${MARKETPLACE_BACKEND_API}drc20/list/enhanced`, {
        params: {
          offset,
          limit,
          sortOrder,
          sortParam,
        },
      })
  )
}

// it is similar to drc20/list and drc20/list/enhanced, named it this way, bc it is used for the table on landingpage
export const getDrc20TableItems = async (
  offset: number,
  limit: number,
  history: string, // 1h | 6h | 24h | 7d | all
  sortParam: 'trending' | 'top'
) => {
  return await executeAsync(
    async () =>
      await axios.get(`${MARKETPLACE_BACKEND_API}drc20/list/activity`, {
        params: {
          action: 'sale',
          offset,
          limit,
          history,
          sortParam,
        },
      })
  )
}

export const getDoginalTableItems = async (
  offset: number,
  limit: number,
  history: string, // 1h | 6h | 24h | 7d | all
  sortParam: 'trending' | 'top'
) => {
  return await executeAsync(
    async () =>
      await axios.get(`${MARKETPLACE_BACKEND_API}doginals/list/activity`, {
        params: {
          action: 'sale',
          offset,
          limit,
          history,
          sortParam,
        },
      })
  )
}

export const getDrc20Data = async (tick: string) => {
  return await executeAsync(async () => await axios.get<Drc20Data>(`${MARKETPLACE_BACKEND_API}drc20/data?tick=${tick}`))
}

export type GetDoginalCollections = {
  collections: Array<DoginalsCollection>
  total: number
}

export const getAllDoginalCollections = async (
  offset: number,
  limit: number,
  filterByName?: string,
  sortOrder?: 'asc' | 'desc',
  sortParam?: 'volume' | 'price' | 'createdAt' | 'launchpadEndDate'
) => {
  return await executeAsync(
    async () =>
      await axios.get<GetDoginalCollections>(MARKETPLACE_BACKEND_API + 'doginals/list', {
        params: {
          limit,
          offset,
          filterByName,
          sortOrder,
          sortParam,
        },
      })
  )
}

export const getFeaturedDoginalCollections = async (
  offset: number,
  limit: number,
  filterByName?: string,
  sortOrder?: 'asc' | 'desc',
  sortParam?: 'volume' | 'price' | 'createdAt' | 'launchpadEndDate'
) => {
  return await executeAsync(
    async () =>
      await axios.get<GetDoginalCollections>(MARKETPLACE_BACKEND_API + 'doginals/list/featured', {
        params: {
          limit,
          offset,
          filterByName,
          sortOrder,
          sortParam,
        },
      })
  )
}

export const getDrc20OfferList = async (
  tick: string,
  offset: number,
  limit: number,
  sortOrder: string,
  sortParam: string,
  status: string = 'listed'
) => {
  const { data } = await axios.get(MARKETPLACE_BACKEND_API + 'offer/drc20/list', {
    params: {
      tick: tick,
      offset: offset,
      limit: limit,
      sortOrder,
      sortParam,
      status,
    },
  })

  return data
}

export const refreshDoginalOfferStatus = async (inscriptionId: string) => {
  return await executeAsync(
    async () =>
      await axios.put<PutRefreshStatus>(
        `${MARKETPLACE_BACKEND_API}offer/doginals/refresh-status?inscriptionId=${inscriptionId}`
      )
  )
}

export const getDoginalsOffersList = async (
  collectionSymbol?: string,
  seller?: string,
  status?: 'listed' | 'sold' | 'unlisted'
) => {
  const params: Record<string, any> = {
    offset: 0,
    limit: 100,
  }

  if (collectionSymbol) {
    params.collectionSymbol = collectionSymbol
  }

  if (seller) {
    params.seller = seller
  }

  if (status) {
    params.status = status
  }

  const { data } = await axios.get(MARKETPLACE_BACKEND_API + 'offer/doginals/listings', {
    params,
  })

  return data.offers
}

export type ActionTypeKeys = 'sale' | 'list' | 'unlist'

type GetDrc20OfferActivityParams = {
  tick: string
  offset: number
  limit?: number
  action?: ActionTypeKeys
  history?: string // 1h | 6h | 24h | 7d
  sortParam?: 'top' | 'recent'
}

export type Drc20OfferActivity = {
  tick: string
  inscriptionId: string
  inscriptionNumber: number
  type: ActionTypeKeys
  price: number
  totalPrice: number
  amount: number
  from: string
  to: string
  createdAt: string
}

type GetDrc20OfferActivityResponse = {
  activities: Array<Drc20OfferActivity>
  total: number
}

type DoginalOfferActivityParams = {
  collectionSymbol: string
  action?: ActionTypeKeys
  offset?: number
  limit?: number
  history?: string // 1h | 6h | 24h | 7d
  sortParam?: 'top' | 'recent'
}

export type DoginalOfferActivity = {
  collectionSymbol: string
  createdAt: string // datestring
  doginalName: string
  from: string
  inscriptionId: string
  inscriptionNumber: number
  price: number
  to: string
  type: ActionTypeKeys
}

export const getDrc20Activity = async ({
  tick,
  offset,
  limit,
  action,
  history,
  sortParam,
}: GetDrc20OfferActivityParams) => {
  const { data } = await axios.get<GetDrc20OfferActivityResponse>(MARKETPLACE_BACKEND_API + 'offer/drc20/activity', {
    params: {
      tick,
      action,
      offset,
      limit,
      history,
      sortParam,
    },
  })

  return data
}

export const getDoginalOfferActivity = async (params: DoginalOfferActivityParams) => {
  const { data } = await axios.get<{ activityList: DoginalOfferActivity[]; total: number }>(
    MARKETPLACE_BACKEND_API + 'offer/doginals/activity',
    {
      params: {
        collectionSymbol: params.collectionSymbol,
        action: params.action,
        offset: params.offset ?? 0,
        limit: params.limit ?? 20,
        history: params.history,
        sortParam: params.sortParam,
      },
    }
  )

  return data
}

export const getPsdtFromOfferId = async (offerId: string, type: 'drc20' | 'doginals') => {
  const { data } = await axios.get(`${MARKETPLACE_BACKEND_API}offer/${type}/psdt-hex?offerId=${offerId}`)

  if (data.status !== 0) {
    return data.psdtHex
  } else {
    throw new Error('No such offer id')
  }
}

export const getBuyerPsdtHex = async (
  buyerAddress: string,
  inscriptionPrice: number,
  sellerPsdtHex: string,
  minerFee: number
) => {
  const { data } = await axios.post(MARKETPLACE_BACKEND_API + 'psdt/buyer/create', {
    buyerAddress: buyerAddress,
    inscriptionPrice: inscriptionPrice,
    sellerPsdtHex: sellerPsdtHex,
    minerFee: minerFee,
  })

  return data.buyerPsdtHex
}

export const getSellerPsdtHex = async (
  sellerAddress: string,
  inscriptionPrice: number,
  transactionId: string,
  transactionOutput: number,
  discount: boolean
) => {
  try {
    const { data } = await axios.post(MARKETPLACE_BACKEND_API + 'psdt/seller/create', {
      sellerAddress: sellerAddress,
      inscriptionPrice: inscriptionPrice,
      transactionId: transactionId,
      transactionOutput: transactionOutput,
      discount: discount,
    })

    return data.sellerPsdtHex
  } catch (e) {
    throw new Error('Error: ' + e)
  }
}

export const getCancelPsdtHex = async (sellerAddress: string, transactionId: string, transactionOutput: number) => {
  const { data } = await axios.post(MARKETPLACE_BACKEND_API + 'psdt/seller/cancel', {
    sellerAddress,
    transactionId,
    transactionOutput,
    minerFee: MINER_FEE,
  })

  return data.sellerCancelPsdtHex
}

export const createDrc20Offer = async (psdtHex: string) => {
  const url = MARKETPLACE_BACKEND_API + 'offer/drc20/create'
  const requestBody = {
    psdtHex: psdtHex,
  }

  return await axios.post(url, requestBody)
}

export const getUtxoFromInscriptionId = async (inscriptionId: string) => {
  const { data } = await axios.get(WALLET_API + 'inscription/utxo', {
    params: {
      inscriptionId: inscriptionId,
    },
  })

  if (data.status === 0) {
    throw new Error(data.message)
  }

  return data.result
}

export const buyDrc20Offer = async (offerId: string, psdtHex: string, buyer: string) => {
  const url = MARKETPLACE_BACKEND_API + 'drc20/buy'
  const requestBody = {
    offerId: offerId,
    psdtHex: psdtHex,
    buyer: buyer,
  }

  return await axios.post(url, requestBody)
}

export const isWhitelistAddress = async (address: string) => {
  try {
    const { data } = await axios.get(MARKETPLACE_BACKEND_API + `whitelist/${address}/status`)
    return data.whitelisted
  } catch (e) {
    console.log("Can't get whitelist addresses")
    return false
  }
}

export const getDoginalCollectionsWhitelist = async (address: string) => {
  try {
    const { data } = await axios.get(MARKETPLACE_BACKEND_API + `whitelist/doginalCollections/${address}`)
    return data.whitelistedDoginalCollections
  } catch (e) {
    console.log("Can't get whitelist addresses")
    return []
  }
}

type GetRandomMessage = {
  randomMessage: string
}

type GetAccessToken = {
  accessToken: string
}

export const getRandomMessage = async (address: string) => {
  return await executeAsync(
    async () => await axios.get<GetRandomMessage>(`${MARKETPLACE_BACKEND_API}auth/message?address=${address}`)
  )
}

export const generateAccessToken = async (address: string, message: string, pubkey: string, signature: string) => {
  return await executeAsync(
    async () =>
      await axios.get<GetAccessToken>(
        `${MARKETPLACE_BACKEND_API}auth/token?address=${address}&message=${message}&pubkey=${pubkey}&signature=${encodeURIComponent(
          signature
        )}`
      )
  )
}

export const unlistDrc20Offer = async (offerId: string, address: string) => {
  return await executeAsync(
    async () =>
      await axios.put(
        `${MARKETPLACE_BACKEND_API}offer/drc20/unlist?offerId=${offerId}`,
        {},
        {
          headers: { Authorization: `Bearer ${(window as any).dogeLabsJwt[address]}` },
        }
      )
  )
}

export const unlistDoginalOffer = async (offerId: string, address: string) => {
  return await executeAsync(
    async () =>
      await axios.put(
        `${MARKETPLACE_BACKEND_API}offer/doginals/unlist?offerId=${offerId}`,
        {},
        {
          headers: { Authorization: `Bearer ${(window as any).dogeLabsJwt[address]}` },
        }
      )
  )
}

type GetDrc20Offer = {
  offer: Drc20Offer
  timestamp: string
}

export const getDrc20Offer = async (inscriptionId: string) => {
  return await executeAsync(
    async () => await axios.get<GetDrc20Offer>(`${MARKETPLACE_BACKEND_API}offer/drc20?inscriptionId=${inscriptionId}`)
  )
}

type GetTx = {
  status: string
  message: string
}

export const getTx = async (txHash: string) => {
  const { data } = await axios.get<GetTx>(`${WALLET_API}tx?hash=${txHash}`)
  return data
}

export const waitUntilTxInMempool = async (txHash: string) => {
  let txFound = false
  while (!txFound) {
    const tx = await getTx(txHash)

    if (tx.status === '1') {
      txFound = true
    }

    await new Promise((resolve) => setTimeout(resolve, 500))
  }
}

type GetDoginalOffer = {
  offer: DoginalOffer
  timestamp: string
}

export const getDoginalOffer = async (inscriptionId: string) => {
  const { data } = await axios.get<GetDoginalOffer>(
    `${MARKETPLACE_BACKEND_API}offer/doginal?inscriptionId=${inscriptionId}`
  )
  return data
}

type GetDogecoinPriceInUsd = {
  dogePriceInUsd: number
}

export const getDogecoinPriceInUsd = async () => {
  return await executeAsync(
    async () => await axios.get<GetDogecoinPriceInUsd>(`${MARKETPLACE_BACKEND_API}config/doge/price`)
  )
}

type GetIsWhitelistedUd = {
  address: string
  whitelisted: boolean
}

export const getIsWhitelistedUd = async (address: string) => {
  return await executeAsync(
    async () => await axios.get<GetIsWhitelistedUd>(`${MARKETPLACE_BACKEND_API}whitelist/${address}/status-ud`)
  )
}

export const getInscriptionContent = async (inscriptionId: string) => {
  return await executeAsync(async () => await axios.get(`https://wonky-ord.dogeord.io/content/${inscriptionId}`))
}

export const getLatestInscriptionsHtml = async () => {
  return await executeAsync(async () => await axios.get('https://wonky-ord.dogeord.io/shibescriptions'))
}

type PutRefreshStatus = {
  valid: boolean
}

export const refreshDrc20OfferStatus = async (inscriptionId: string) => {
  return await executeAsync(
    async () =>
      await axios.put<PutRefreshStatus>(
        `${MARKETPLACE_BACKEND_API}offer/drc20/refresh-status?inscriptionId=${inscriptionId}`
      )
  )
}

export const createDoginalCollection = async (collectionData: DoginalsCollectionDto, address: string) => {
  return await executeAsync(async () => {
    const response = await axios.post(`${MARKETPLACE_BACKEND_API}doginals/collection`, collectionData, {
      headers: { Authorization: `Bearer ${(window as any).dogeLabsJwt[address]}` },
    })
    return response.data.collection
  })
}

// Function to create individual doginals
export const createDoginal = async (dogeData: DoginalsCollectionData, address: string) => {
  const response = await axios.post(`${MARKETPLACE_BACKEND_API}doginals/doginal/create`, dogeData, {
    headers: { Authorization: `Bearer ${(window as any).dogeLabsJwt[address]}` },
  })
  return response.data.doginals
}

// Function to retrieve information about a specific doginal collection
export const getDoginalCollectionInfo = async (collectionSymbol: string) => {
  try {
    const response = await axios.get(
      `${MARKETPLACE_BACKEND_API}doginals/collection-info?collectionSymbol=${collectionSymbol}`
    )
    return response.data
  } catch (error) {
    console.error('Error retrieving doginal collection info:', error)
    throw error
  }
}
export const getCollectionMetadata = async (collectionSymbol: string) => {
  try {
    const response = await axios.get(
      `${MARKETPLACE_BACKEND_API}doginals/collection-metadata?collectionSymbol=${collectionSymbol}`
    )
    return response.data
  } catch (error) {
    console.error('Error retrieving doginal collection metadata:', error)
    throw error
  }
}

// Function to retrieve doginals associated with a specific collection
export const getDoginalsFromCollection = async (
  collectionSymbol: string,
  limit: number,
  offset: number,
  sortOrder: string,
  metadataKey?: string,
  metadataValue?: string,
  listed?: boolean
): Promise<{ total: number; doginals: Doginal[] }> => {
  try {
    const params: Record<string, any> = {
      collectionSymbol,
      limit,
      offset,
      sortOrder,
      ...(metadataKey && metadataValue ? { metadataKey, metadataValue } : {}),
    }

    if (listed !== undefined) {
      params.listed = listed
    }

    const response = await axios.get(`${MARKETPLACE_BACKEND_API}doginals/collection`, {
      params,
    })

    return {
      total: response.data.total,
      doginals: response.data.doginals,
    }
  } catch (error) {
    console.error('Error retrieving collection doginals:', error)
    throw error
  }
}

// Function to retrieve information about a specific doginal
export const getDoginalInfo = async (inscriptionId: string) => {
  try {
    const response = await axios.get(
      `${MARKETPLACE_BACKEND_API}doginals/collection/token?inscriptionId=${inscriptionId}`
    )
    return response.data.token
  } catch (error) {
    console.error('Error retrieving doginal info:', error)
    throw error
  }
}

// Function to retrieve information about a list of doginals
const chunkArray = (array: Array<string>, chunkSize: number) => {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

export const getDoginalInfos = async (inscriptionIds: Array<string>): Promise<Array<any>> => {
  try {
    const chunkSize = 10
    const inscriptionIdChunks = chunkArray(inscriptionIds, chunkSize)

    const promises = inscriptionIdChunks.map(async (chunk): Promise<Array<any>> => {
      const inscriptionIdsQuery = chunk.map((id) => `inscriptionIds[]=${id}`).join('&')
      const url = `${MARKETPLACE_BACKEND_API}doginals/collection/tokens?${inscriptionIdsQuery}`
      const response = await axios.get(url)
      return response.data.token
    })

    // Use Promise.all to fetch data for all chunks in parallel
    const results = await Promise.all(promises)

    // Flatten the array of results and return
    return results.flat()
  } catch (error) {
    console.error('Error retrieving doginal infos:', error)
    throw error
  }
}

// Function to buy a doginal offer
export const buyDoginal = async (offerId: string, psdtHex: string, buyer: string) => {
  const url = `${MARKETPLACE_BACKEND_API}doginals/buy`
  const requestBody = {
    offerId: offerId,
    psdtHex: psdtHex,
    buyer: buyer,
  }

  try {
    const response = await axios.post(url, requestBody)
    return response.data
  } catch (error) {
    console.error('Error buying doginal offer:', error)
    throw error
  }
}

// Function to retrieve the balance of doginals associated with a specific address
export const getDoginalsBalance = async (doginalBalanceQuery: DoginalBalanceQuery) => {
  try {
    const { address, cursor, size } = doginalBalanceQuery
    const response = await axios.get(
      `${MARKETPLACE_BACKEND_API}doginals/balance?address=${address}&cursor=${cursor}&size=${size}`
    )
    return response.data.balance
  } catch (error) {
    console.error('Error retrieving doginals balance:', error)
    throw error
  }
}

export const createDoginalOffer = async (psdtHex: string) => {
  const url = MARKETPLACE_BACKEND_API + 'offer/doginals/create'
  const requestBody = {
    psdtHex: psdtHex,
  }

  return await axios.post(url, requestBody)
}

export const getDoginalCollectionSymbol = async (inscriptionId: string) => {
  const url = `${MARKETPLACE_BACKEND_API}doginals/${inscriptionId}/collection-symbol`
  const response = await axios.get(url)
  return response.data.collectionSymbol
}

export type Feature = {
  title?: string
  header?: string
  category?: string
  description: {
    [paragraphNumber: number]: string
  }
  CTAText: string
  CTALink: string
  imageLink: string
  index: number | null
  visible: boolean
}

export type FeatureDto = {
  header: string
  description: {
    [paragraphNumber: number]: string
  }
  CTAText: string
  CTALink: string
  imageLink: string
  visible?: boolean
}

export const createFeature = async (featureDto: FeatureDto, address: string) => {
  const response = await axios.post(`${MARKETPLACE_BACKEND_API}features`, featureDto, {
    headers: { Authorization: `Bearer ${(window as any).dogeLabsJwt[address]}` },
  })

  return response
}

export const getFeatures = async (visible?: boolean, featureName?: string) => {
  try {
    const response = await axios.get<Feature[]>(`${MARKETPLACE_BACKEND_API}features`, {
      params: { visible, featureName: featureName || undefined },
    })

    return response.data
  } catch (err) {
    Sentry.captureException(err, {
      extra: {
        visibility: visible,
      },
    })
    console.log('error fetching features', err)
  }
}

export const updateFeatures = async (features: Feature[], address: string) => {
  const response = await axios.put(
    `${MARKETPLACE_BACKEND_API}features`,
    { features },
    {
      headers: { Authorization: `Bearer ${(window as any).dogeLabsJwt[address]}` },
    }
  )

  return response
}

export type PadMint = {
  collectionCreationTimestamp: string //datestring
  description: string
  discordLink: string
  floorPrice: number
  holders: number
  imageURI: string
  launchpadEarlyAccessMinutes: number
  launchpadEndDate: string //datestring
  launchpadFeatured: boolean
  launchpadFundingWallet: string
  launchpadMaxMintsPerWallet: number
  launchpadMintedSupply: number
  launchpadPriceInDoge: number
  launchpadSupply: string
  maxInscriptionNumber: string
  minInscriptionNumber: string
  name: string
  sales: number
  supply: number
  symbol: string
  totalListed: number
  totalVolume: number
  twitterLink: string
  visible: boolean
  websiteLink: string
}

export const getLaunchpadMints = async (params: Record<string, any>) => {
  const queryParams: Record<string, any> = {
    limit: params.limit,
    offset: params.offset,
    sort: params.sort,
    sortOrder: params.sortOrder,
  }

  const response = await axios.get(`${MARKETPLACE_BACKEND_API}launchpad/list/active`, {
    params: { ...queryParams },
  })

  return response
}

export const getMintpadMints = async (params: Record<string, any>) => {
  const queryParams: Record<string, any> = {
    limit: params.limit,
    offset: params.offset,
    sort: params.sort,
    sortOrder: params.sortOrder,
  }

  const response = await axios.get(`${MARKETPLACE_BACKEND_API}mintpad/list/active`, {
    params: { ...queryParams },
  })

  return response
}

// trending
export type HotDrc20Trending = {
  added: string // datestring
  change7d: number
  change24h: number
  deployer: string
  deploymentShibescription: string
  holders: number
  limitPerMint: number
  marketCap: number
  minted: number
  mintingTransactions: number
  price: number
  rank: number
  supply: number
  tick: string
  trustLevel: number
  volume7d: number
  volume24h: number
  _id: string
}

// fairEntry
export type HotDrc20Fair = {
  added: string
  fairEntry: number
  holders: number
  limitPerMint: number
  minted: number
  mintedSupply: number
  mintingTransactions: number
  rank: number
  supply: number
  tick: string
  _id: string
}

export const getHotDrc20 = async (sortBy: SortBy, params?: Record<string, any>) => {
  const getSortParamFromSortingType = (sortingType: SortBy) => {
    switch (sortingType) {
      case SortBy.Trending:
        return 'trending'
      case SortBy.FairEntry:
        return 'fairEntry'
      case SortBy.EndsSoon:
        return 'remainingSupply'
      default:
        return 'trending'
    }
  }

  const queryParams: Record<string, any> = {
    size: params ? params.size : 10,
    page: params ? params.page : 0,
  }
  const url = `${DRC20_API}ticks/list/${getSortParamFromSortingType(sortBy)}`
  const response = await axios.get(url, { params: { ...queryParams } })
  return { res: response.data.data }
}

export const fetchBurnInfosByAddress = async (address: string): Promise<BurnInfo[]> => {
  const url = `${
    import.meta.env.VITE_API_ENDPOINT_URL || 'https://d20-api.dogeord.io'
  }/doginals/burnInfos/byAddress/${address}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  })

  if (!response.ok) throw new Error('Could not get BurnInfo by address')

  return (await response.json()) as [BurnInfo]
}

export const fetchBurnedDoginalsByAddress = async (address: string): Promise<Labradoge[]> => {
  try {
    const allBurnInfos = await fetchBurnInfosByAddress(address)
    // Dont use already fully revealed doginals for burned doginals
    const burnInfos = allBurnInfos.filter((info) => !info.revealedInscriptionId && !info.revealedCollectionSymbol)

    // get all collection symbols from burnInfos without duplicates
    const collectionSymbols = [...new Set(burnInfos.map((info) => info.collectionSymbol))]

    // fetch each collection's infos and prepare doginals
    let burnedDoginals: Labradoge[] = []
    for (const collectionSymbol of collectionSymbols) {
      const collectionInfo = await getDoginalCollectionInfo(collectionSymbol)
      const collection = collectionInfo.collection as CollectionInfo

      // filter out burnInfos for this collection
      const currentCollectionsBurnInfos = burnInfos.filter((info) => info.collectionSymbol === collectionSymbol)

      // create burnedDoginals from burnInfos and collection info
      const currentCollectionsDoginals = currentCollectionsBurnInfos.map((info) => {
        return {
          image: info?.doginalImageUri || collection.imageURI,
          name: `Burned ${collection.name.toUpperCase()}`,
          collectionSymbol: info.collectionSymbol,
          id: 0,
          inscriptionId: info.inscriptionId,
          burnInfo: info,
        }
      })

      burnedDoginals = burnedDoginals.concat(currentCollectionsDoginals)
    }

    return burnedDoginals.reverse()
  } catch (err) {
    console.error('Failed to fetch burned doginals', err)
    return []
  }
}

export const fetchRevealedDoginalsByAddress = async (address: string): Promise<Labradoge[]> => {
  try {
    // fetch each collection's infos
    const burnInfos = await fetchBurnInfosByAddress(address)

    // filter out burnInfos that are not revealed yet
    const revealedBurnInfos = burnInfos.filter((info) => info.revealedInscriptionId && info.revealedCollectionSymbol)

    // filter out revealedBurnInfos that revealedInscriptionIds and revealedCollectionSymbols are not set
    const revealedInscriptionIds = revealedBurnInfos
      .filter((info) => info.revealedCollectionSymbol && info.revealedInscriptionId)
      .map((info) => info.revealedInscriptionId && info.revealedInscriptionId.toString())
      .filter((id): id is string => id !== undefined)

    const revealedDoginalInfos = await getDoginalInfos(revealedInscriptionIds)
    return revealedDoginalInfos.map((doginalData: any) => transformToLabradoge(doginalData))
  } catch (err) {
    console.error('Failed to fetch revealed doginals', err)
    return []
  }
}

export const fetchBurnInfo = async (txHash: string): Promise<BurnInfo> => {
  const url = `${import.meta.env.VITE_API_ENDPOINT_URL || 'https://d20-api.dogeord.io'}/doginals/burnInfo/${txHash}`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  })

  if (!response.ok) throw new Error('Could not get BurnInfo by txHash')

  return (await response.json()) as BurnInfo
}

export const sendBurnInfo = async (burnInfo: BurnInfo, attempt = 1) => {
  try {
    const url = `${import.meta.env.VITE_API_ENDPOINT_URL || 'https://d20-api.dogeord.io'}/doginals/burnInfo`
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers you need to send
      },
      body: JSON.stringify(burnInfo),
    })

    if (!response.ok) throw new Error('Fetch failed')
    // Handle your response here
  } catch (error) {
    console.error(`Attempt ${attempt} failed with error: ${error}`)
    if (attempt < 20) {
      const delay = 5 * attempt
      console.log(`Retrying in ${delay} seconds...`)
      setTimeout(() => sendBurnInfo(burnInfo, attempt + 1), delay * 1000)
    } else {
      console.error('All attempts to send burn Info failed.')
    }
  }
}

export const updateBurnInfo = async (burnInfo: BurnInfo, attempt = 1) => {
  try {
    const url = `${import.meta.env.VITE_API_ENDPOINT_URL || 'https://d20-api.dogeord.io'}/doginals/burnInfo`
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        // Add any other headers you need to send
      },
      body: JSON.stringify(burnInfo),
    })

    if (!response.ok) throw new Error('Patch failed')
    // Handle your response here
  } catch (error) {
    console.error(`Attempt ${attempt} failed with error: ${error}`)
    if (attempt < 20) {
      const delay = 5 * attempt
      console.log(`Retrying in ${delay} seconds...`)
      setTimeout(() => updateBurnInfo(burnInfo, attempt + 1), delay * 1000)
    } else {
      console.error('All attempts to patch burn Info failed.')
    }
  }
}

export const fetchBurnInfoByInscriptionId = async (inscriptionId: string): Promise<BurnInfo> => {
  const url = `${
    import.meta.env.VITE_API_ENDPOINT_URL || 'https://d20-api.dogeord.io'
  }/doginals/burnInfo/byInscriptionId/${inscriptionId}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  })

  if (!response.ok) throw new Error('Could not get BurnInfo by inscriptionId')

  return (await response.json()) as BurnInfo
}

export const fetchDoginals = async (
  collectionInformation: ImageInscriptionResponse[],
  filterCollectionNames: string[] | null | undefined
): Promise<Labradoge[]> => {
  try {
    const inscriptionIds = collectionInformation.map((info) => info.inscriptionId)

    // Fetch data for all inscriptionIds in a single call
    const doginalsData = await getDoginalInfos(inscriptionIds)

    // Transform and filter doginals
    const filteredDoginals: Labradoge[] = doginalsData
      .map((doginalData: any) => {
        try {
          return transformToLabradoge(doginalData)
        } catch (err) {
          console.warn('Failed to transform doginal data:', err)
          return null
        }
      })
      .filter(
        (doginal: Labradoge | null) =>
          doginal !== null &&
          doginal &&
          (filterCollectionNames && Array.isArray(filterCollectionNames)
            ? (filterCollectionNames as string[]).includes(doginal.collectionSymbol)
            : true)
      ) as Labradoge[]

    // // Fetch burnInfo for all filtered doginals and add it to the doginal object
    const doginalPromises = filteredDoginals.map(async (doginal: Labradoge) => {
      try {
        const burnInfo = await fetchBurnInfoByInscriptionId(doginal.inscriptionId.toString())
        return { ...doginal, burnInfo }
      } catch (err) {
        // console.warn('Failed to fetch burn info:', err)
        return doginal
      }
    })

    const doginals = await Promise.all(doginalPromises)

    // Filter out null values
    return doginals.filter((doginal: Labradoge | null) => doginal !== null) as Labradoge[]
  } catch (err) {
    console.error('Failed to fetch doginals', err)
    return []
  }
}

export const transformToLabradoge = (data: any): Labradoge => {
  return {
    image: data.imageURI,
    name: data.name,
    collectionSymbol: data.collectionSymbol,
    id: data.inscriptionNumber,
    inscriptionId: data.inscriptionId,
    // attributes: data.metadata,
    // rarity: 0,
  }
}

export type LabradogeMetadataSet = {
  value?: string
  label?: string
  probability?: number
}

export type Labradoge = {
  name: string
  image: string
  id: number
  inscriptionId: number | string
  collectionSymbol: string
  attributes?: LabradogeMetadataSet[]
  rarity?: number
  burnInfo?: BurnInfo
}

export type BurnInfo = {
  txHash: string
  address: string
  inscriptionId: string
  collectionSymbol: string
  revealedInscriptionId?: string
  revealedCollectionSymbol?: string
  doginalImageUri?: string
  burnStatus?: RevealProcessingStatus
  updatedAt?: string
  createdAt?: string
}

export type CollectionInfo = {
  symbol: string
  name: string
  description: string
  imageURI: string
}

export enum RevealProcessingStatus {
  MemoryPool = 'memoryPool',
  Burned = 'burned',
  InProgress = 'inProgress',
  ToBeInscribed = 'toBeInscribed',
  Completed = 'completed',
  Inscribed = 'inscribed',
  UploadedToMarketplace = 'uploadedToMarketplace',
  Failed = 'failed',
}

import axios from 'axios'
import { useMediaQuery } from 'usehooks-ts'

import { getLatestInscriptionsHtml } from '@/api'

import { LandingPageLatestInscriptionCard } from '../components/cards/LandingPageLatestInscriptionCard'
import { LoadingLatestInscriptionCard } from '../components/cards/LoadingLatestInscriptionCard'
import { LandingPageTitle } from '../components/title/LandingPageTitle'

const LandingPageLatestInscriptions = () => {
  const isTablet = useMediaQuery('(min-width: 768px)')
  const isDesktop = useMediaQuery('(min-width: 1024px)')

  const [latestInscriptions, setLatestInscriptions] = useState<unknown[]>([])
  const fetchLatestInscriptions = useCallback(async () => {
    const { res } = await getLatestInscriptionsHtml()

    const getContentFromOrdinalExplorer = async (inscriptionId: string) => {
      try {
        const { data, headers } = await axios.get(`https://wonky-ord.dogeord.io/content/${inscriptionId}`)
        return { content: data, inscriptionId, contentType: headers['content-type'] }
      } catch (e) {
        return e
      }
    }

    const getInscriptionIdFromOutputHtml = (html?: string) => {
      if (!html) {
        return undefined
      }

      const parser = new DOMParser()
      const document = parser.parseFromString(html, 'text/html')
      const anchors = document.querySelectorAll("a[href^='/shibescription/']")
      let shibescriptionIds = []
      if (anchors?.length > 0) {
        for (const [key, value] of Object.entries(anchors)) {
          if (Number(key) <= 7) {
            shibescriptionIds.push(value.getAttribute('href')?.split('/shibescription/')[1])
          }
        }

        return shibescriptionIds
      } else {
        return undefined
      }
    }
    const inscriptionIds = getInscriptionIdFromOutputHtml(res?.data)
    const inscriptions = await Promise.all(
      //@ts-ignore
      inscriptionIds && inscriptionIds.map(async (inscriptionId) => await getContentFromOrdinalExplorer(inscriptionId))
    )
    setLatestInscriptions(inscriptions)
  }, [])

  useEffect(() => {
    fetchLatestInscriptions()
  }, [fetchLatestInscriptions])

  return (
    <div>
      <LandingPageTitle blackText="Latest" orangeText="Inscriptions" style={{ margin: '80px 0 24px 0' }} />
      <div className="grid gap-x-8 gap-y-8 grid-rows-[repeat(3, 1fr)] grid-cols-[repeat(1,1fr)] sm:grid-rows-[repeat(2, 1fr)] sm:grid-cols-[repeat(2,1fr)] md:grid-rows-[repeat(2, 1fr)] md:grid-cols-[repeat(3,1fr)] lg:grid-rows-[repeat(2, 1fr)] lg:grid-cols-[repeat(4,1fr)]">
        {latestInscriptions?.length
          ? // @ts-ignore
            latestInscriptions.map(({ contentType, content, inscriptionId }, idx) => {
              if (idx + 1 > 3 && !isTablet) return null
              return (
                <LandingPageLatestInscriptionCard
                  key={idx}
                  jsonContent={content as object}
                  inscriptionId={inscriptionId as string}
                  contentType={contentType as string}
                />
              )
            })
          : Array(isDesktop ? 8 : isTablet ? 4 : 3)
              .fill({})
              .map((_, idx) => <LoadingLatestInscriptionCard key={idx} />)}
      </div>
    </div>
  )
}

export { LandingPageLatestInscriptions }

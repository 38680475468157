import { SwiperSlide } from 'swiper/react'

import { getHotDrc20 } from '@/api'
import { calculateDrc20Fairness } from '@/utils'

import {
  LandingPageInscriptionCard,
  LandingPageInscriptionCardProps,
} from '../components/cards/LandingPageInscriptionCard'
import { LoadingInscriptionCard } from '../components/cards/LoadingInscriptionCard'
import { SwiperSlider } from '../components/slider/SwiperSlider'
import { LandingPageSortingPill } from '../components/sorting/LandingPageSortingPill'
import { LandingPageTitle } from '../components/title/LandingPageTitle'

export enum SortBy {
  Trending = 'Trending',
  FairEntry = 'Fair Entry',
  EndsSoon = 'Ends Soon',
}

const LandingPageHotDRC20s = () => {
  const [hotDrc20s, setHotDrc20s] = useState<LandingPageInscriptionCardProps[]>([])
  const [sortingType, setSortingType] = useState<SortBy>(SortBy.Trending)

  const fetchHotDrc20 = useCallback(async () => {
    try {
      const { res } = await getHotDrc20(sortingType, {
        page: 0,
        size: 50,
      })

      let filtered = res
        ?.filter((x: any) => x.supply > x.minted || x.minted === 0)
        .map((x: any) => ({
          tick: x.tick,
          total: (x.minted / x.supply) * 100,
          holders: x.holders,
          content: JSON.stringify({ p: 'drc-20', op: 'mint', tick: x.tick, amt: x.limitPerMint.toString() }, null, 2),
          totalSupply: x.supply,
          fairness: calculateDrc20Fairness(Number(x.supply), x.limitPerMint),
          // TODO: calculate trending
          // trending: Math.floor(Math.random() * (100 - 1 + 1) + 1),
          id: x._id,
        }))

      filtered && setHotDrc20s(filtered)
    } catch (e) {
      console.log(e)
    }
  }, [sortingType])

  useEffect(() => {
    fetchHotDrc20()
  }, [fetchHotDrc20])

  return (
    <div className="mb-[48px]">
      <LandingPageTitle blackText="Hot" orangeText="drc-20" style={{ marginBottom: 0 }} />
      <div className="w-full flex flex-wrap gap-[8px] justify-between items-center mt-[24px] mb-[48px]">
        <LandingPageSortingPill
          options={Object.values(SortBy)}
          defaultValue={sortingType}
          onChange={(x) => {
            setSortingType(x as SortBy)
          }}
        />
      </div>
      <SwiperSlider spaceBetween={32}>
        {hotDrc20s.length > 0
          ? hotDrc20s.map(({ content, fairness, holders, tick, total, id }) => (
              <SwiperSlide key={id}>
                <LandingPageInscriptionCard
                  id={id}
                  content={content}
                  fairness={fairness}
                  holders={holders}
                  tick={tick}
                  total={total}
                  customCardWidth="w-[252px]"
                  // trending={trending}
                />
              </SwiperSlide>
            ))
          : Array(15)
              .fill({})
              .map((_, idx) => (
                <div key={idx} className="mr-3 h-[570px]">
                  <SwiperSlide key={idx}>
                    <LoadingInscriptionCard />
                  </SwiperSlide>
                </div>
              ))}
      </SwiperSlider>
    </div>
  )
}

export { LandingPageHotDRC20s }

type TimeRemaining = {
  days: number
  hours: number
  minutes: number
}

// pad numbers with leading zeros
const padNumber = (number: number, length: number) => {
  return String(number).padStart(length, '0')
}

const calculateTimeRemaining = (startTimestamp: number, endTimestamp: number): TimeRemaining => {
  const remainingInMilliseconds = endTimestamp - startTimestamp
  const oneDay = 24 * 60 * 60 * 1000
  const oneHour = 60 * 60 * 1000
  const oneMinute = 60 * 1000

  const days = Math.floor(remainingInMilliseconds / oneDay)
  const hours = Math.floor((remainingInMilliseconds % oneDay) / oneHour) + days * 24
  const minutes = Math.floor((remainingInMilliseconds % oneHour) / oneMinute)

  return { days, hours, minutes }
}

export { calculateTimeRemaining, padNumber }
export type { TimeRemaining }

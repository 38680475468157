import { Skeleton } from 'antd'

export const LoadingRow = () => {
  return (
    <tr className="h-[90px]">
      <td className="whitespace-nowrap py-4 text-xs text-start">
        <Skeleton.Button active shape="circle" size="small" />
      </td>
      <td className="whitespace-nowrap py-4 text-xs text-start">
        <div className="flex items-center">
          <Skeleton.Button active shape="square" style={{ height: '63px', width: '63px', marginRight: '12px' }} />
          <Skeleton.Input active block size="small" style={{ width: '80px', borderRadius: '6px' }} />
        </div>
      </td>
      <td className="whitespace-nowrap py-4 text-xs text-end">
        <Skeleton.Input active block size="small" style={{ width: '120px', borderRadius: '6px' }} />
      </td>
      <td className="hidden sm:block whitespace-nowrap py-4 text-xs h-full">
        <div className="flex flex-col h-full justify-center  text-end">
          <Skeleton.Input active block size="small" style={{ width: '120px', borderRadius: '6px' }} />
        </div>
      </td>
    </tr>
  )
}

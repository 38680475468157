import { CloseOutlined, LinkOutlined, MenuOutlined, TwitterOutlined } from '@ant-design/icons'
import { Image, Layout, Menu } from 'antd'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'usehooks-ts'

import { DiscordIcon } from '@/assets/icons/discord'
import { TwitterXIcon } from '@/assets/icons/twitterX'
import Disclaimer, { disclaimerLatestVersionDate } from '@/components/Disclaimer'
import WalletConnect from '@/components/WalletConnect'

import './index.css'

const { Header, Content, Footer } = Layout

const PageBaseDogepad = ({ children }: { children: any }) => {
  const navigator = useNavigate()
  const { pathname } = useLocation()
  const [disclaimerAcceptedAt, setDisclaimerAcceptedAt] = useLocalStorage('disclaimerAcceptedAt', 0)
  const [menuState, setMenuState] = useState<string[]>([])
  const navigationItems = [
    { key: 'doginalDogs', label: 'Doginal Dogs', disabled: false },
    { key: 'dogeLabs', label: 'Doge Labs', disabled: false },
  ]

  const navigateTo = (e: any) => {
    switch (e.key) {
      case 'home':
        navigator('/dogepad')
        break
      case 'doginalDogs':
        window.open('https://drc-20.org/marketplace/doginals/doginal-dogs', '_blank')
        break
      case 'dogeLabs':
        window.open('https://drc-20.org/', '_blank')
        break
      default:
        console.error(e)
    }
  }

  const activeTab = navigationItems.find((item) => pathname?.includes(item.key))?.key || 'home'

  // @ts-ignore
  return (
    <Layout
      id="Dogepad"
      className="layout"
      style={{
        backgroundColor: '#000000',
        // color: '#FFFFFF',
        height: '100%',
        minHeight: '100vh',
      }}
    >
      <div
        style={{
          backgroundColor: '#000000',
          position: 'relative',
          height: '150px',
          zIndex: 1,
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Header
          style={{
            backgroundColor: '#000000',
            position: 'relative',
            height: '150px',
            zIndex: 1,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            maxWidth: '1280px',
            margin: '0 auto',
          }}
        >
          <title>Dogepad | powered by drc-20.org</title>
          <meta name="description" content="Dogepad | powered by drc-20.org" />
          <link rel="icon" href="/favicon.ico" />
          <div className="mt-7">
            <a href="/dogepad" target="_self" rel="noopener noreferrer">
              <Image src="/images/dogepad/logo-dogepad-beta.svg" alt="Logo" width={103} height={99} preview={false} />
            </a>
          </div>
          <Menu
            className="ant-menu-style"
            style={{
              backgroundColor: '#000000',
              color: '#FFFFFF',
              fontSize: '14px',
              fontWeight: 'bold',
              top: 0,
              right: '20px',
              marginLeft: '60px',
              borderBottom: 0,
              justifyContent: 'flex-start',
            }}
            mode="horizontal"
            defaultSelectedKeys={['home']}
            selectedKeys={[activeTab]}
            items={navigationItems}
            onClick={navigateTo}
            onOpenChange={(newState) => setMenuState(newState)}
            overflowedIndicator={menuState.indexOf('rc-menu-more') > -1 ? <CloseOutlined /> : <MenuOutlined />}
          />
          <a
            href="https://twitter.com/dogepadlaunch"
            target="_blank"
            rel="noreferrer"
            className="hide-mobile"
            style={{ color: 'white', lineHeight: '60px', padding: '0 8px', fontSize: '24px' }}
          >
            <TwitterXIcon />
          </a>
          <a
            href="https://discord.com/invite/fjtwfDFHFr"
            target="_blank"
            rel="noreferrer"
            className="hide-mobile"
            style={{ color: 'white', lineHeight: '60px', padding: '0 8px', fontSize: '24px' }}
          >
            <DiscordIcon />
          </a>
          <div className="ml-2 hidden lg:block">
            <WalletConnect
              accountPage="/dogepad"
              bgColor="#FEBF22"
              bgColorLoggedIn="#FEBF22"
              hightlightColor="#000000"
              textColor="#000000"
              hideDoggy
            />
          </div>
        </Header>
      </div>
      <Content
        id="dogepad-content"
        style={{
          padding: '0 16px',
          textAlign: 'center',
          background: 'rgba(0, 0, 0, 0.75)',
          borderTop: '3px solid #FEBF22',
        }}
      >
        {/*100vh - 414px for footer and header*/}
        <div
          className="App relative p-4 min-h-[calc(100vh-414px)]"
          style={{
            maxWidth: '1280px',
            margin: '0 auto',
            paddingTop: '50px',
            paddingBottom: '150px',
          }}
        >
          {disclaimerAcceptedAt > disclaimerLatestVersionDate ? (
            children
          ) : (
            <Disclaimer acceptDisclaimer={setDisclaimerAcceptedAt} />
          )}
        </div>
      </Content>
      <Footer
        style={{
          backgroundColor: '#FEBF22',
          color: '#64748b',
          fontSize: '16px',
          height: '296px',
          margin: '0 auto',
          width: '100%',
        }}
      >
        <div
          className="flex flex-row justify-between"
          style={{
            background: '#FEBF22',
            color: '#000000',
            fontSize: '16px',
            justifyContent: 'center',
            maxWidth: '1280px',
            margin: '0 auto',
            width: '100%',
          }}
        >
          <div className="flex flex-col mt-8">
            <p className="text-2xl uppercase text-center">Join the</p>
            <p className="text-2xl uppercase text-center">community</p>
          </div>
        </div>
        <div className="flex flex-col flex-center mt-8">
          <div className="flex flex-row flex-center text-xl">
            <a
              href="https://twitter.com/dogepadlaunch"
              target="_blank"
              rel="noreferrer"
              style={{
                lineHeight: '48px',
                padding: '0 8px',
                color: '#FEBF22',
                backgroundColor: '#000000',
                borderRadius: '10px',
                height: '46px',
                margin: '4px',
                width: '46px',
                textAlign: 'center',
              }}
            >
              <TwitterXIcon />
            </a>
            <a
              href="https://discord.com/invite/fjtwfDFHFr"
              target="_blank"
              rel="noreferrer"
              style={{
                lineHeight: '48px',
                padding: '0 8px',
                color: '#FEBF22',
                backgroundColor: '#000000',
                borderRadius: '10px',
                height: '46px',
                margin: '4px',
                width: '46px',
                textAlign: 'center',
              }}
            >
              <DiscordIcon />
            </a>
            <a
              href="https://drc-20.org/dogepad"
              target="_blank"
              rel="noreferrer"
              style={{
                lineHeight: '48px',
                padding: '0 8px',
                color: '#FEBF22',
                backgroundColor: '#000000',
                borderRadius: '10px',
                height: '46px',
                margin: '4px',
                width: '46px',
                textAlign: 'center',
              }}
            >
              <LinkOutlined />
            </a>
          </div>
        </div>
      </Footer>
      <p
        className="text-2xl font-bold text-center my-8"
        style={{
          color: '#FEBF22',
        }}
      >
        DOGEPAD
      </p>
    </Layout>
  )
}

export default PageBaseDogepad

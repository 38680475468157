import { Typography } from 'antd'

const { Title } = Typography

type LandingPageTitleProps = {
  blackText: string
  orangeText: string
  blackStyle?: React.CSSProperties
  popStyle?: React.CSSProperties
  style?: React.CSSProperties
}

const LandingPageTitle = ({ blackText, orangeText, popStyle, blackStyle, style }: LandingPageTitleProps) => {
  return (
    <Title className="text-left mt-[48px] mb-[12px]" style={style}>
      <span className="text-3xl mb-inherit text-[#000] font-bold" style={blackStyle && { ...blackStyle }}>
        {blackText}
      </span>
      &nbsp;
      <span className="text-3xl mb-inherit font-bold" style={popStyle ? { ...popStyle } : { color: '#F9B628' }}>
        {orangeText}
      </span>
    </Title>
  )
}

export { LandingPageTitle }

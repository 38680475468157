import { cardHoverAnimation } from '../../LandingPage'

type LandingPageLatestInscriptionCardProps = {
  jsonContent: object
  inscriptionId: string
  contentType: string
}

const LandingPageLatestInscriptionCard = ({
  contentType,
  jsonContent,
  inscriptionId,
}: LandingPageLatestInscriptionCardProps) => {
  const asJSON = contentType.includes('text/plain')
  const content = JSON.stringify(jsonContent, null, 2)

  return (
    <a
      href={`https://wonky-ord.dogeord.io/shibescription/${inscriptionId}`}
      target="_blank"
      rel="noreferrer"
      className="!no-underline"
    >
      <div
        className={`max-w-[270px] h-[240px] flex justify-center items-center rounded-[15px] shadow-[0px_6px_16px_0px_#0000001A] bg-[#DFDFDF] cursor-pointer ${cardHoverAnimation}`}
      >
        <div className="w-[93%] h-[93%] rounded-[15px] bg-[#F6F6F6]">
          <div className="h-full flex justify-center items-center text-left">
            {asJSON ? (
              <span className="text-md select-none" style={{ whiteSpace: 'pre-line' }}>
                {content}
              </span>
            ) : (
              <img
                src={`https://wonky-ord.dogeord.io/content/${inscriptionId}`}
                className="h-full w-full block object-cover rounded-[15px]"
              />
            )}
          </div>
        </div>
      </div>
    </a>
  )
}
export { LandingPageLatestInscriptionCard }

export const calculateDrc20Fairness = (totalSupply: number, limitPerMint: number) => {
  const log = Math.log(totalSupply / limitPerMint) * 6.15
  if (log > 100) {
    return 100
  } else if (log <= 1) {
    return 1
  } else {
    return Math.round(log)
  }
}

export const calculateDrc20Trending = (mintTransactionsAmtWithin: number) => {
  const log = Math.log(mintTransactionsAmtWithin) * 11
  if (log > 100) {
    return 100
  } else if (log <= 1) {
    return 1
  } else {
    return Math.round(log)
  }
}

export type HeadTitles = {
  headerOne: string
  headerTwo: string
  headerThree: string
  headerFour: string
  headerFive?: string
}

export const TableHead = ({ headerOne, headerTwo, headerThree, headerFour, headerFive }: HeadTitles) => {
  return (
    <thead className="w-full border-b border-b-[#2B2E41] border-solid">
      <tr className="w-full">
        <th className="hidden sm:block w-[64px] whitespace-nowrap py-4 pl-4 text-xs text-start font-medium text-slate-600">
          {headerOne}
        </th>
        <th className="whitespace-nowrap py-4 text-xs text-start font-medium text-slate-600">{headerTwo}</th>
        <th className="xs:pr-4 sm:pr-0 whitespace-nowrap py-4 text-xs text-end font-medium text-slate-600">
          {headerThree}
        </th>
        <th className="hidden sm:block whitespace-nowrap py-4 pr-4 text-xs text-end font-medium text-slate-600">
          {headerFour}
        </th>
        {headerFive && (
          <th className="whitespace-nowrap py-4 pr-4 text-xs text-end font-medium text-slate-600">{headerFive}</th>
        )}
      </tr>
    </thead>
  )
}

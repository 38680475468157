import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { getDoginalInfo, transformToLabradoge } from '@/api'
import AmountInput from '@/components/AmountInput'
import { revealCollectionNames } from '@/components/doginalpacks/collection'
import ServiceContentContainer from '@/components/ServiceContentContainer'
import { CustomButton, Text } from '@/components/ui'
import { ImageWrapper } from '@/components/ui/imageWrapper'
import { useRevealData } from '@/hooks/useRevealData'
import { useWallet } from '@/hooks/useWallet'
import { Labradoge } from '@/types/labradoge'

import styles from './revealOverview.module.scss'

export function RevealOverview({ navigateBack = false }) {
  const { pathname } = useLocation()
  const inscriptionId = pathname.split('/')[3]

  const navigate = useNavigate()
  if (navigateBack) {
    navigate(`/labradoges/collection`)
  }

  const {
    availableLabradogeAmount,
    selectedLabradogeRevealAmount,
    setCollections,
    labradoges,
    selectedLabradoge,
    onReveal,
    handleLabradogeSelection,
    isRevealingError,
  } = useRevealData()

  const { connected } = useWallet()

  if (isRevealingError) {
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }

  useEffect(() => {
    setCollections(revealCollectionNames)

    getDoginalInfo(inscriptionId).then((res) => {
      const doginal: Labradoge = transformToLabradoge(res)
      handleLabradogeSelection(doginal)
    })
  }, [inscriptionId, handleLabradogeSelection, setCollections])

  return (
    <>
      <ServiceContentContainer>
        {isRevealingError && (
          <div className="col-span-full mt-10 flex justify-center items-center gap-6">
            <p className="text-xl font-bold text-red-500">
              There is an error in burning you doginal. Most likely you will need to reset your wallet's state and try
              again.
            </p>
          </div>
        )}
        <ImageWrapper className="mb-6 mt-4" src={selectedLabradoge ? selectedLabradoge.image : undefined} size="big" />
        <Text className={`${styles.text} mb-2`} size="xl" fontWeight="extraBold">
          {/*{(connected && `Select number of ${selectedLabradoge?.collectionSymbol} to swap`) ||*/}
          {(connected && `Open Pack`) || 'Connect your wallet to start the transition'}
        </Text>
        <div className={`${styles.selectionContainer} mb-2`}>
          <div className={`${styles.infoContainer} mb-2`}>
            <div className={`${styles.textContainer} mb-2`}>
              <Text color="dark-grey" className={styles.reRollText} size="md">
                Total num of {selectedLabradoge?.collectionSymbol} collection Doginals in wallet:{' '}
                <span className={`${styles.reRollAmount}`}>{availableLabradogeAmount}</span>
              </Text>
            </div>
            <Text size="sm">
              Get packs{' '}
              <a rel="noreferrer noopener" target="_blank" href="https://drc-20.org/marketplace/doginals/investor-pack">
                here
              </a>
              .
            </Text>
          </div>
          {/*{connected && (*/}
          {/*  <AmountInput*/}
          {/*    // for now only one...*/}
          {/*    // max={availableLabradogeAmount}*/}
          {/*    max={1}*/}
          {/*    value={selectedLabradogeRevealAmount}*/}
          {/*    onChange={setSelectedLabradogeRevealAmount}*/}
          {/*  />*/}
          {/*)}*/}
        </div>
        <CustomButton
          isDisabled={!selectedLabradoge || !labradoges}
          className={styles.button}
          onClick={onReveal}
          label={!labradoges ? 'Loading' : 'Reveal'}
        />
        <div className={`${styles.textContainer} mt-2 mb-2`}>
          <Text size="md" className={styles.disclaimer}>
            By clicking “Reveal”, you agree to burn {selectedLabradogeRevealAmount}{' '}
            <strong>{selectedLabradoge?.collectionSymbol}</strong> {selectedLabradogeRevealAmount <= 1 ? 'NFT' : 'NFTs'}{' '}
            and agree to the <a href="https://terms.drc-20.org">Terms of Use</a>.
          </Text>
        </div>
      </ServiceContentContainer>
    </>
  )
}

import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import { Collection } from '@/components/doginalpacks/collection'
import { Packs } from '@/components/doginalpacks/packs'
import SwitchButton from '@/components/doginalpacks/SwitchButton'
import { CollectionProvider } from '@/provider/collectionProvider/collectionProvider'

import PageBase from '../_base'

interface DoginalsProps {
  activeTab?: string
}

const Doginals = ({ activeTab = undefined }: DoginalsProps) => {
  const isActiveValue = activeTab === 'collection'
  const [isActive, setIsActive] = useState<boolean>(isActiveValue)

  return (
    <CollectionProvider>
      <PageBase>
        <SwitchButton
          activeTabURIs={['/labradoges/packs', '/labradoges/collection']}
          isActive={isActive}
          setIsActive={setIsActive}
        />
        {!isActive ? (
          <div className="mt-15">
            <Packs />
          </div>
        ) : (
          <div>
            <Collection isActive={isActive} />
          </div>
        )}
      </PageBase>
    </CollectionProvider>
  )
}

export default Doginals

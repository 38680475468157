import { Skeleton } from 'antd'

const LoadingInscriptionCard = () => {
  return (
    <div className="flex-col min-w-[302px] max-w-[340px]">
      <div className=" w-[98%] mx-auto my-0 mb-[24px]">
        <Skeleton.Input
          active
          block
          size="small"
          style={{ display: 'flex', width: '100%', height: '8px', borderRadius: '12px' }}
        />
      </div>

      <div className="h-[470px] rounded-[15px] flex-col overflow-hidden shadow-[0px_6px_16px_0px_#0000001A]">
        <div className="h-3/5 w-full bg-[lightgrey]" />
        <div className="h-2/5 w-full p-[24px] grid grid-cols-[repeat(3,1fr)] grid-rows-[repeat(2,1fr)] gap-x-3 gap-y-3">
          <Skeleton.Input active block size="small" style={{ borderRadius: '6px' }} />
          <Skeleton.Input active block size="small" style={{ borderRadius: '6px' }} />

          <div />
        </div>
      </div>
    </div>
  )
}

export { LoadingInscriptionCard }

import { Typography } from 'antd'

import { LandingPageAccordion } from '../components/accordion/LandingPageAccordion'

const { Title } = Typography

const LandingPageLearnMore = () => {
  const infos = [
    {
      id: 0,
      question: 'What are Doginals?',
      answer: (
        <span>
          Dogecoin Ordinals, or 'Doginals', are a brand new functionality and utility for Dogecoin.They enable projects
          and users to write (‘inscribe’) information stored on the smallest individual units of a Dogecoin ('shibes').
          <br />
          <br />
          Simply put: <b>Doginals are crypto assets on Dogecoin.</b> <br />
          <br /> Similar to how Ordinals inscribe information onto the smallest units of Bitcoin, Doginals inscribe
          information onto the smallest units of Dogecoin.
        </span>
      ),
    },
    {
      id: 1,
      question: 'Which wallet do you recommend to use for Doginals?',
      answer: (
        <span>
          For the best and safest experience, we recommend the{' '}
          <a
            href="https://chromewebstore.google.com/detail/doge-labs-wallet/jiepnaheligkibgcjgjepjfppgbcghmp"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Doge Labs Wallet
          </a>{' '}
          - the number one Dogecoin wallet on Google’s Chrome web store. It's fully compatible with drc-20s, Doge NFTs,
          and has the broadest integration within the Doginals ecosystem.
          <br />
          <br />
          Aside from providing a beautiful home for your Doginals, the Doge Labs Wallet lets you store and transfer your
          inscriptions safely, smoothly handles UTXO dust, and enables you to manage multiple separate wallets and
          sub-accounts - all within a single extension.
          <br />
          <br />
          With over 50,000 users, the Doge Labs Wallet gives users full control over their Dogecoin and Doginal assets.
          Just remember to always secure your seed phrase!
        </span>
      ),
    },
    {
      id: 2,
      question: `Do you have a getting started guide?`,
      answer: (
        <span>
          New to Doginals and looking for a couple easy steps to get started? Look no further than our 3-step{' '}
          <a
            href="https://doge-labs.gitbook.io/doginals/the-basics/get-started-here"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Getting Started guide.
          </a>{' '}
          <br />
          <br />
          We’ll teach you how to download your very first Doginals wallet, how to get some funds ready, and how to
          connect to{' '}
          <a href="https://drc-20.org/" target="_blank" rel="noreferrer" className="underline">
            {' '}
            drc-20.org
          </a>{' '}
          in order to start creating, collecting, and even selling your very own Dogecoin Ordinals!
        </span>
      ),
    },
    {
      id: 3,
      question: 'What is Doge Labs?',
      answer: (
        <span>
          Doge Labs is the primary infrastructure provider within the Dogecoin Ordinals (aka 'Doginals') ecosystem. We
          are most well-known for establishing the first-ever drc-20 standard on DOGE.
          <br />
          <br />
          Between the Doge Labs Wallet, inscription service, launchpad, and first-ever automated marketplace on
          Dogecoin, our goal is to support the Doginals ecosystem and ensure that Dogecoin Ordinals are safe, fun, and
          primed for wider market adoption and acceptance.
        </span>
      ),
    },
    {
      id: 4,
      question: 'What are Ordinals?',
      answer: (
        <span>
          Ordinals are a numbering scheme for Dogecoin shibes (the smallest units of DOGE). They enable us to track and
          transfer individual shibes across different wallets.
          <br />
          <br />
          Each shibe is assigned a number in the order in which they're mined, and are transferred from "transaction
          inputs" to "transaction outputs" in a first-in-first-out basis.
          <br />
          <br />
          They're called "ordinals" because both the number and the transfer schemes each rely on their respective
          order. Numbering is based on the order in which shibes are mined, and transfers are based on the order of the
          transaction inputs and outputs.
        </span>
      ),
    },
    {
      id: 5,
      question: 'Where can I learn more?',
      answer: (
        <span>
          Would you like more information on all things Doginals? Here are a few places we suggest you investigate next:
          <br />
          <br />
          <ul style={{ listStyleType: 'circle' }}>
            <li style={{ marginLeft: '36px' }}>
              Looking for a full breakdown? Here’s our{' '}
              <a href="https://doge-labs.gitbook.io/doginals/" target="_blank" rel="noreferrer" className="underline">
                {' '}
                Gitbook
              </a>{' '}
              on the topic.
            </li>
            <li style={{ marginLeft: '36px' }}>
              Need a wallet? Look no further than the{' '}
              <a
                href="https://chromewebstore.google.com/detail/doge-labs-wallet/jiepnaheligkibgcjgjepjfppgbcghmp"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                number one Dogecoin wallet on Chrome.
              </a>
            </li>
            <li style={{ marginLeft: '36px' }}>
              Want to stay up to date? Check out our{' '}
              <a href="https://twitter.com/verydogelabs" target="_blank" rel="noreferrer" className="underline">
                Twitter
              </a>{' '}
              page for all of the latest information.
            </li>
            <li style={{ marginLeft: '36px' }}>
              Need help (or just hate Twitter)? Our{' '}
              <a href="http://discord.gg/fjtwfDFHFr" target="_blank" rel="noreferrer" className="underline">
                Discord channel
              </a>{' '}
              is here for you.
            </li>
          </ul>
        </span>
      ),
    },
  ]
  return (
    <div className="flex flex-col justify-center items-center mb-[120px]">
      <div className="my-[48px]">
        <span
          className="text-4xl md:text-7xl font-bold mb-inherit text-[#000]"
          style={{ textAlign: 'center', marginBottom: '12px' }}
        >
          Learn more
        </span>
        <Title className="max-w-[500px] text-center">
          <span
            className="text-4xl md:text-7xl font-bold mb-inherit text-[#000]"
            style={{ textAlign: 'center', marginBottom: '12px' }}
          >
            about
          </span>
          &nbsp;
          <span
            className="text-4xl md:text-7xl md:ml-[8px] font-bold mb-inherit whitespace-nowrap"
            style={{
              background: 'linear-gradient(71.36deg, #FFB627 0%, #F38401 56.77%, #ED6401 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Doginals
          </span>
        </Title>
      </div>

      <LandingPageAccordion infos={infos} />
    </div>
  )
}

export { LandingPageLearnMore }

import cn from 'classnames'
import { MdRefresh } from 'react-icons/md'

const noop = () => {}

const RefreshButton = ({
  className,
  refetchData,
  setIsSorting,
}: {
  className?: string
  refetchData: () => void
  setIsSorting: (isSorting: boolean) => void
}) => {
  const [isRefreshing, setIsRefreshing] = useState(false)

  const handleRefreshClick = useCallback(async () => {
    setIsSorting(true)
    setIsRefreshing(true)
    await refetchData()
    setIsSorting(false)
    setIsRefreshing(false)
  }, [refetchData, setIsSorting])

  return (
    <button
      className={cn(
        'xxs:hidden xs:flex border-2 border-[#EFF2F5] rounded-lg text-amber-500 text-xl p-1 hover:text-amber-700',
        className
      )}
      disabled={isRefreshing}
      onClick={isRefreshing ? noop : handleRefreshClick}
    >
      <MdRefresh className={cn({ spin: isRefreshing })} />
    </button>
  )
}

export default RefreshButton

import { useEffect, useState } from 'react'

import { fetchDoginals } from '@/api'
import { useGetInscriptionsByAddress } from '@/hooks/useGetInscriptionsByAddress'
import { Labradoge } from '@/types/labradoge'

export const useGetLabradogesData = (address: string, connected: boolean, filterCollectionNames?: null | string[]) => {
  const [doginals, setDoginals] = useState<Labradoge[]>([])
  const [loading, setLoading] = useState(false)
  const [complete, setComplete] = useState(false)
  const [error, setError] = useState<Error | unknown | null>(null)

  const { collectionInformation } = useGetInscriptionsByAddress({
    address,
    connected,
  })

  useEffect(() => {
    if (collectionInformation && filterCollectionNames) {
      const fetchDoginalInformation = async () => {
        try {
          setLoading(true)
          const filteredDoginals = await fetchDoginals(collectionInformation, filterCollectionNames)
          setDoginals(filteredDoginals as Labradoge[])
          setComplete(true)
        } catch (err) {
          setError(err)
        } finally {
          setLoading(false)
        }
      }

      fetchDoginalInformation().then()

      // Then set up the interval to call it every 10 seconds
      const intervalId = setInterval(fetchDoginalInformation, 15000)

      // Clear the interval when the component unmounts or dependencies change
      return () => clearInterval(intervalId)
    }
  }, [collectionInformation, filterCollectionNames])

  return { doginals, loading, complete, error }
}

import cn from 'classnames'

import styles from './loadingView.module.scss'
import tennisBall from './OG-Ball.png'

export function LoadingView({ isFixed = false }) {
  return (
    <div
      className={cn(styles.animationContainer, {
        [styles.fixedPosition]: isFixed,
      })}
    >
      <img alt="Tennis Ball" src={tennisBall} className={styles.tennisBall} />
      <img alt="Tennis Ball" src={tennisBall} className={styles.tennisBall} />
      <img alt="Tennis Ball" src={tennisBall} className={styles.tennisBall} />
    </div>
  )
}

// used for sortings tables on landingpage

export enum SortingType {
  Trending = 'Trending',
  Top = 'Top',
}

export enum SortingTimeFrame {
  OneHour = '1h',
  SixHours = '6h',
  TwentyFourHours = '24h',
  SevenDays = '7d',
}

export enum SortingTimeFrameWithAll {
  OneHour = '1h',
  SixHours = '6h',
  TwentyFourHours = '24h',
  SevenDays = '7d',
  All = 'all',
}
export type TopDrc20Params = {
  offset: number
  limit: number
  sortOrder: 'asc' | 'desc'
  sortParam: 'volume' | 'price'
}

import { createContext, useMemo } from 'react'

import { useGetBurnedDoginalsData } from '@/hooks/useGetBurnedDoginalsData'
import { useGetLabradogesData } from '@/hooks/useGetLabradogesData'
import { useGetRevealedDoginalsData } from '@/hooks/useGetRevealedDoginalsData'
import { useWallet } from '@/hooks/useWallet'
import { CollectionApi, CollectionProviderProps } from '@/provider/collectionProvider/collectionProvider.types'
import { Labradoge } from '@/types/labradoge'

const defaultContext: CollectionApi = {
  setCollections: ([]) => undefined,
  doginals: [],
  burnedDoginals: [],
  revealedDoginals: [],
  selectedDoginal: undefined,
  setSelectedDoginal: () => undefined,
  collectionLoadingStatus: undefined,
  burnedCollectionLoadingStatus: undefined,
  revealedCollectionLoadingStatus: undefined,
}

export const CollectionContext = createContext(defaultContext)

export const CollectionProvider = ({ children }: CollectionProviderProps) => {
  const { address, connected } = useWallet()
  const [selectedDoginal, setSelectedDoginal] = useState<Labradoge | undefined>(undefined)
  const [collections, setCollections] = useState<null | string[]>(null)

  const { doginals, error, loading, complete } = useGetLabradogesData(address, connected, collections)
  const {
    burnedDoginals,
    error: burnedDoginalsError,
    loading: burnedDoginalsLoading,
    complete: burnedDoginalsComplete,
  } = useGetBurnedDoginalsData(address, connected)
  const {
    revealedDoginals,
    error: revealedDoginalsError,
    loading: revealedDoginalsLoading,
    complete: revealedDoginalsComplete,
  } = useGetRevealedDoginalsData(address, connected)

  const collectionLoadingStatus = useMemo(
    () => ({
      error,
      loading,
      completed: complete,
    }),
    [error, loading, complete]
  )

  const burnedCollectionLoadingStatus = useMemo(
    () => ({
      error: burnedDoginalsError,
      loading: burnedDoginalsLoading,
      completed: burnedDoginalsComplete,
    }),
    [burnedDoginalsError, burnedDoginalsLoading, burnedDoginalsComplete]
  )

  const revealedCollectionLoadingStatus = useMemo(
    () => ({
      error: revealedDoginalsError,
      loading: revealedDoginalsLoading,
      completed: revealedDoginalsComplete,
    }),
    [revealedDoginalsError, revealedDoginalsLoading, revealedDoginalsComplete]
  )

  const api: CollectionApi = useMemo(
    () => ({
      setCollections,
      selectedDoginal,
      doginals,
      burnedDoginals,
      revealedDoginals,
      setSelectedDoginal,
      collectionLoadingStatus,
      burnedCollectionLoadingStatus,
      revealedCollectionLoadingStatus,
    }),
    [
      selectedDoginal,
      doginals,
      burnedDoginals,
      revealedDoginals,
      collectionLoadingStatus,
      burnedCollectionLoadingStatus,
      revealedCollectionLoadingStatus,
    ]
  )
  return <CollectionContext.Provider value={api}>{children}</CollectionContext.Provider>
}

import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Collapse } from 'antd'
import { ReactNode } from 'react'

const { Panel } = Collapse
type LandingPageAccordionProps = {
  infos: { id: number; question: string; answer: ReactNode }[]
  isAccordionMode?: boolean
}
const LandingPageAccordion = ({ infos, isAccordionMode = true }: LandingPageAccordionProps) => {
  return (
    <Collapse
      accordion={isAccordionMode}
      defaultActiveKey={[1]}
      onChange={() => {}}
      expandIconPosition="end"
      expandIcon={({ isActive }) => (isActive ? <UpOutlined /> : <DownOutlined />)}
      className="bg-[#fff] xxs:w-full md:w-[688px] rounded-[15px] pb-[16px] text-left pt-[20px] border-b-[#d9d9d9] border-b border-solid"
    >
      {infos.map(({ question, answer, id }) => (
        <Panel className="font-bold text-[21px]" header={question} key={id}>
          <p className="font-normal text-[#86838a] text-[16px] border-t-[none]">{answer}</p>
        </Panel>
      ))}
    </Collapse>
  )
}

export { LandingPageAccordion }

import { Typography } from 'antd'

import { LandingPagePrimaryButton } from '../components/button/LandingPagePrimaryButton'

const { Title } = Typography

const LandingPageDogeWalletBanner = () => {
  return (
    <div className="w-full h-[636px] mt-[-150px] relative flex justify-center items-center">
      <img
        src="/images/landing-page-background.svg"
        alt="Collection Images"
        className="absolute top-[0px] object-cover h-full z-[0]"
      />
      <div className="w-[1280px] h-full relative">
        <div className="absolute z-[1] w-full h-full grid grid-cols-[repeat(4,1fr),] grid-rows-[1fr,] top-0">
          <div></div>
          <div className="min-w-[280px] mx-0 my-auto xxs:hidden md:block">
            <img src="/images/doge-wallet-mockup.svg" alt="Doge Labs Wallet Mockup" className="object-cover" />
          </div>
          <div className="min-w-[300px] lg:min-w-[630px] flex flex-col gap-[24px] justify-center items-center">
            <div className="h-fit">
              <Title className="text-center !mb-0">
                <span className="text-[48px] lg:text-[82px] text-[82px] font-700 text-5xl">The</span>
                &nbsp;
                <span
                  className="text-[48px] lg:text-[82px] font-700 text-5xl text-[#000]"
                  style={{
                    background: 'linear-gradient(71.36deg, #FFB627 0%, #F38401 56.77%, #ED6401 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Doge
                </span>
              </Title>
              <Title className="sm:text-[48px] text-left !mt-2 mb-0">
                <span
                  className="text-[48px] lg:text-[82px] font-700 text-5xl"
                  style={{
                    background: 'linear-gradient(71.36deg, #FFB627 0%, #F38401 56.77%, #ED6401 100%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Labs
                </span>
                &nbsp;
                <span className="text-[48px] lg:text-[82px] font-700 text-5xl text-[#000]">Wallet</span>
              </Title>
            </div>
            <span className="inline-block text-[14px] text-center md:text-[22px] md:text-left">
              Join 50,000+ users and help pioneer Ordinals on Doge.
            </span>
            <LandingPagePrimaryButton
              styles={{
                height: '46px',
                color: '#000',
                fontWeight: 'bold',
                fontSize: '14px',
                borderRadius: '32px',
                marginTop: '12px',
                padding: '6px 28px',
              }}
              disabled={false}
              onClick={() => {
                window.open(
                  'https://chrome.google.com/webstore/detail/doge-labs-wallet/jiepnaheligkibgcjgjepjfppgbcghmp',
                  '_blank'
                )
              }}
            >
              <span>Get Started</span>
              <img src="/images/link.svg" alt="Arrow Right" className="inline-block ml-[8px] mb-[4px] h-[20px]" />
            </LandingPagePrimaryButton>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export { LandingPageDogeWalletBanner }

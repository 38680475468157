import React from 'react'
import { useNavigate } from 'react-router'

import { cardHoverAnimation } from '../../LandingPage'
import { LandingPagePrimaryButton } from '../button/LandingPagePrimaryButton'

type LandingPageDoginalCardProps = {
  startBlock: number
  endBlock: number
  floorPrice: string
  name: string
  imageURL: string
  symbol: string
  totalSupply: number
}

const LandingPageDoginalCard = ({
  endBlock,
  floorPrice,
  imageURL,
  name,
  startBlock,
  symbol,
  totalSupply,
}: LandingPageDoginalCardProps) => {
  const handleClickView = () => {
    window.open(`https://doge-labs.com/#/collectible/${symbol}`, '_blank')
  }

  return (
    <div
      className={`h-[400px] w-[252px] flex flex-col justify-center ml-[12px] md:ml-[12px] md:w-[320px] xl:w-[280px] ${cardHoverAnimation}`}
    >
      <div className="relative h-[360px] w-full rounded-[12px] flex-col p-[12px] shadow-[0px_6px_16px_0px_#0000001A]">
        <div className="absolute top-[24px] right-[24px] bg-[#00000026] backdrop-blur-[10px] rounded-[15px] text-[white] px-[12px] py-[2px] text-[12px]">
          {`#${startBlock.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })} - #${endBlock.toLocaleString('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}`}
        </div>
        <img
          className="h-[60%] w-full block object-cover rounded-[15px] mr-[16px] mb-[16px]"
          src={imageURL}
          alt="Drc20"
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => (e.currentTarget.src = '/ticks/noicon.png')}
        />
        <div className="h-[40%] flex flex-col justify-between px-[12px] pb-[24px]">
          <div className="mx-[8px] mt-[8px]">
            <div className="w-full flex justify-between text-[14px] font-bold">
              <span className="w-[120px] md:w-[180px] text-left overflow-hidden whitespace-nowrap text-ellipsis">
                {name}
              </span>
              <span className="w-24">{floorPrice} Ɖ</span>
            </div>
            <div className="w-full flex justify-between text-[#888]">
              <span className="text-[12px]">Supply: {totalSupply?.toLocaleString()}</span>
              <span className="text-[12px] w-24">Floor Price</span>
            </div>
          </div>
          <LandingPagePrimaryButton styles={{ width: '100%', height: '42px' }} onClick={handleClickView}>
            View
          </LandingPagePrimaryButton>
        </div>
      </div>
    </div>
  )
}

export { LandingPageDoginalCard }
export type { LandingPageDoginalCardProps }

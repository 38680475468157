import { Image } from 'antd'
import React from 'react'

import { getDoginalCollectionInfo, getDoginalCollectionsWhitelist } from '@/api'
import LaunchpadCollectionCard, { LaunchpadCollectionCardProps } from '@/components/launchpad/LaunchpadCollectionCard'
import { MintingPageContainer } from '@/components/launchpad/MintingPageContainer'
import { dogepadCollectionSymbols } from '@/pages/service/dogepad'
import { useWalletContext } from '@/WalletContext'

import styles from './packs.module.scss'

export function Packs() {
  const { address } = useWalletContext()
  const [doginalCollectionsList, setDoginalCollectionsList] = useState<any>([])
  const [launchpadEarlyAccessWhitelistCollections, setLaunchpadEarlyAccessWhitelistCollections] = useState<string[]>([])

  const fetchCollectionData = useCallback(
    async (symbol) => {
      try {
        const collectionResult = await getDoginalCollectionInfo(symbol)
        const collection = collectionResult.collection

        const updatedList = doginalCollectionsList.map((collectionListItem: any) => {
          if (collectionListItem.symbol === collection.symbol) {
            return collection
          }
          return collectionListItem
        })
        setDoginalCollectionsList(updatedList)

        return collection
      } catch (err) {
        console.error(`Failed to fetch info for collection: ${symbol}`, err)
        return null
      }
    },
    [doginalCollectionsList]
  )

  const fetchCollectionsData = useCallback(async () => {
    const doginalsList = await Promise.all(
      dogepadCollectionSymbols.map(async (symbol: string) => {
        try {
          const collectionResult = await getDoginalCollectionInfo(symbol)
          return collectionResult.collection
        } catch (err) {
          console.error(`Failed to fetch info for collection: ${symbol}`, err)
          return null
        }
      })
    )
    setDoginalCollectionsList(doginalsList)
  }, [])

  useEffect(() => {
    fetchCollectionsData()
  }, [fetchCollectionsData])

  useEffect(() => {
    const fetchWhitelist = async () => {
      if (address) {
        const whitelistedCollections = await getDoginalCollectionsWhitelist(address)
        setLaunchpadEarlyAccessWhitelistCollections(whitelistedCollections)
      }
    }
    fetchWhitelist()
  }, [address])

  // Filter out null values
  const filteredDoginalCollectionsList = doginalCollectionsList.filter(
    (doginalCollection: any) => doginalCollection !== null
  )

  let collections: LaunchpadCollectionCardProps[] = []
  if (filteredDoginalCollectionsList) {
    collections = filteredDoginalCollectionsList.map(
      (collection: any) =>
        ({
          ...collection,
          imgSrc: collection.imageURI,
          altText: collection.name,
          mintingConfig: {
            maxMintsPerWallet: collection.launchpadMaxMintsPerWallet,
            dogePerMint: collection.launchpadPriceInDoge,
            fixedMinters: 1,
            showStatus: true,
            fundingWalletAddress: collection.launchpadFundingWallet,
            supply: collection.launchpadSupply,
            whitelistedForEarlyAccess: launchpadEarlyAccessWhitelistCollections.includes(collection.symbol),
          },
          steps: [
            {
              label: collection.name,
            },
            {
              label: 'Thank you!',
            },
          ],
          info: collection.description,
          isActive: true,
          launchpadEndTimestamp: new Date(collection.launchpadEndDate),
        } as unknown as LaunchpadCollectionCardProps)
    )
  }

  return (
    <>
      <h1 className="text-xl font-bold mb-4 text-white text-left">
        Live{' '}
        <span className="text-xxs">
          powered by{' '}
          <a href="/" target="_self" rel="noopener noreferrer">
            <Image
              src="/logo.svg"
              alt="Logo"
              width={24}
              height={24}
              preview={false}
              style={{ marginTop: '8px', marginLeft: '4px' }}
            />
          </a>
        </span>
      </h1>
      <MintingPageContainer>
        {collections.map((collection, index): any => (
          <div className="self-start" key={`${collection}${index}`} style={{ maxWidth: '360px' }}>
            <LaunchpadCollectionCard
              isActive={collection.isActive}
              name={collection.name}
              symbol={collection.symbol}
              steps={collection.steps}
              altText={collection.altText}
              imgSrc={collection.imgSrc}
              mintingConfig={collection.mintingConfig}
              description={collection.description}
              twitterLink={collection.twitterLink}
              discordLink={collection.discordLink}
              websiteLink={collection.websiteLink}
              launchpadEndTimestamp={collection.launchpadEndTimestamp}
              launchpadEarlyAccessMinutes={collection.launchpadEarlyAccessMinutes}
              launchpadMintedSupply={collection.launchpadMintedSupply}
              highlightColor="#FEBF22"
              darkmode={true}
              fetchCollectionData={fetchCollectionData}
            />
          </div>
        ))}
        <div className="flex-col flex items-start mb-12 lg:col-span-2 px-16" style={{ maxWidth: '600px' }}>
          <h1 className="text-xl font-bold mb-4 text-white">About Doginal Dogs</h1>
          <div className="flex-col flex items-start justify-start text-white">
            <p className="text-left">
              Explore the World of Doginal Dogs: 10,000 Distinctive Pixel Dogs inscribed on the Doge Blockchain
            </p>
            <p className="mt-4 text-left">
              Adopt your own furry friend inscribed on $DOGE. Similar to Bitcoin Ordinals, these inscriptions are
              forever, making every Doginal Dog not just unique but also permanent. Unlike NFTs, which are separate
              tokens, each Doginal Dog is directly embedded in the blockchain. The holder of each inscription gains
              access to the IP rights associated with their Doginal Dog.
            </p>
          </div>
        </div>
      </MintingPageContainer>
    </>
  )
}

import { useMediaQuery } from 'usehooks-ts'

import { getEnhancedDrc20List, getInscriptionContent } from '@/api'
import { Drc20Data } from '@/types/drc20'
import { calculateDrc20Fairness } from '@/utils'

import {
  LandingPageInscriptionCard,
  LandingPageInscriptionCardProps,
} from '../components/cards/LandingPageInscriptionCard'
import { LoadingInscriptionCard } from '../components/cards/LoadingInscriptionCard'
import { LandingPageTitle } from '../components/title/LandingPageTitle'

const LandingPageNewlyDeployed = () => {
  const isTablet = useMediaQuery('(min-width: 768px)')
  const isLarge = useMediaQuery('(min-width: 1280px)')
  const [newlyDeployed, setNewlyDeployed] = useState<LandingPageInscriptionCardProps[]>([])

  const fetchNewlyDeployed = async () => {
    try {
      const { res } = await getEnhancedDrc20List(0, 20, 'desc', 'createdAt')
      let filtered = res?.data
        .sort(
          (a: Drc20Data, b: Drc20Data) =>
            Number(a.currentSupply) / Number(a.maxSupply) - Number(b.currentSupply) / Number(b.maxSupply)
        )
        .slice(0, 3)
        .map((x: Drc20Data) => ({
          tick: x.tick,
          total: (Number(x.currentSupply) / Number(x.maxSupply)) * 100,
          holders: x.holders,
          inscriptionId: x.inscriptionId,
          totalSupply: x.maxSupply,
        }))
      const completed = await Promise.all(
        filtered.map(async (x: Drc20Data) => {
          const inscriptionInfo = x.inscriptionId && (await getInscriptionContent(x.inscriptionId))
          // @ts-expect-error TODO: type properly
          const limitPerMint = Number(inscriptionInfo?.res?.data?.amt)

          return {
            ...x,
            fairness: calculateDrc20Fairness(Number(x.totalSupply), limitPerMint),
            // TODO: calculate trending
            // trending: Math.floor(Math.random() * (100 - 1 + 1) + 1),
            content: JSON.stringify(
              { p: 'drc-20', op: 'deploy', tick: x.tick, max: x.totalSupply, amt: limitPerMint.toString() },
              null,
              2
            ),
          }
        })
      )

      completed && setNewlyDeployed(completed)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchNewlyDeployed()
  }, [])

  return (
    <div className="mb-[48px]">
      <LandingPageTitle blackText="Newly" orangeText="Deployed" style={{ marginTop: '80px', marginBottom: '24px' }} />
      <div className="mr-3 grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 grid-rows-1">
        {newlyDeployed.length > 0
          ? newlyDeployed.map(({ content, fairness, holders, tick, total, id }, index) => {
              if ((!isLarge && index === 2) || (!isTablet && [1, 2].includes(index))) return null
              return (
                <LandingPageInscriptionCard
                  key={index}
                  id={id}
                  content={content}
                  fairness={fairness}
                  holders={holders}
                  tick={tick}
                  total={total}
                  customCardWidth="w-[272px]"
                  // trending={trending}
                />
              )
            })
          : Array(3)
              .fill({})
              .map((_, idx) => <LoadingInscriptionCard key={idx} />)}
      </div>
    </div>
  )
}

export { LandingPageNewlyDeployed }

import { AnyARecord } from 'dns'
import { SwiperSlide } from 'swiper/react'

import { getAllDoginalCollections, getDoginalsFromCollection } from '@/api'
import { DoginalsCollection } from '@/types/dogeNft'
import { breakDownPriceInDoge, toTimestamp } from '@/utils'
import { ONE_DOGE_IN_SHIBES } from '@/utils/constants'

import { LandingPageDoginalCard, LandingPageDoginalCardProps } from '../components/cards/LandingPageDoginalCard'
import { LoadingDoginalCard } from '../components/cards/LoadingDoginalCard'
import { SwiperSlider } from '../components/slider/SwiperSlider'
import { LandingPageTitle } from '../components/title/LandingPageTitle'

const LandingPageNewCollections = () => {
  const [newCollections, setNewCollections] = useState<LandingPageDoginalCardProps[]>([])

  const soonerReleasesFirst = (a: any, b: any) => {
    let comparison = 0
    if (toTimestamp(b.collectionCreationTimestamp) > toTimestamp(a.collectionCreationTimestamp)) {
      comparison = 1
    } else if (toTimestamp(b.collectionCreationTimestamp) < toTimestamp(a.collectionCreationTimestamp)) {
      comparison = -1
    }
    return comparison
  }

  const fetchNewCollections = useCallback(async () => {
    try {
      const { res } = await getAllDoginalCollections(0, 20, undefined, 'desc', 'createdAt')

      const collections: any = res?.data?.collections

      // get floor price for collections with floor price = 0
      const zeroFloorPriceCollections = collections?.filter(
        (collection: any) => collection.floorPrice === 0 || collection.floorPrice > 9000000000000000
      )

      const collectionsWithFloorPrice = await Promise.all(
        zeroFloorPriceCollections.map(async (collection: any) => {
          const { doginals } = await getDoginalsFromCollection(collection.symbol, 1, 0, 'asc')
          if (!doginals || doginals.length < 1) {
            return { symbol: collection.symbol, floorPrice: 0 }
          }
          const floorPrice = doginals[0].price
          return { symbol: collection.symbol, floorPrice }
        })
      )

      const enhancedCollections = res!.data.collections.map((collectionItem) => {
        const hasFloorPrice = collectionsWithFloorPrice.find((c) => c.symbol === collectionItem.symbol)
        return hasFloorPrice ? { ...collectionItem, floorPrice: hasFloorPrice.floorPrice } : collectionItem
      })

      const sortedCollections = enhancedCollections?.sort(soonerReleasesFirst).map((x: DoginalsCollection) => ({
        endBlock: Number(x.minInscriptionNumber),
        floorPrice: breakDownPriceInDoge(x.floorPrice, { notation: 'compact', compactDisplay: 'short' }),
        imageURL: x.imageURI,
        name: x.name,
        startBlock: Number(x.maxInscriptionNumber),
        symbol: x.symbol,
        totalSupply: x.supply,
      }))

      res && setNewCollections(sortedCollections as LandingPageDoginalCardProps[])
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    fetchNewCollections()
  }, [fetchNewCollections])

  return (
    <div className="mb-[48px]">
      <LandingPageTitle blackText="New" orangeText="Collections" style={{ marginBottom: 0 }} />
      <div className="h-fit">
        <SwiperSlider spaceBetween={24} isLoop={false}>
          {newCollections?.length
            ? newCollections.map(({ endBlock, floorPrice, imageURL, name, startBlock, symbol, totalSupply }, idx) => (
                <SwiperSlide key={idx}>
                  <LandingPageDoginalCard
                    endBlock={endBlock}
                    floorPrice={floorPrice}
                    imageURL={imageURL}
                    name={name}
                    startBlock={startBlock}
                    symbol={symbol}
                    totalSupply={totalSupply}
                  />
                </SwiperSlide>
              ))
            : Array(3)
                .fill({})
                .map((_, idx) => (
                  <SwiperSlide key={idx}>
                    <LoadingDoginalCard />
                  </SwiperSlide>
                ))}
        </SwiperSlider>
      </div>
    </div>
  )
}

export { LandingPageNewCollections }

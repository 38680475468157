import { Progress, Tooltip } from 'antd'
import React from 'react'
import { MdInfoOutline } from 'react-icons/md'
import { useNavigate } from 'react-router'

import { useWalletContext } from '@/WalletContext'

import { cardHoverAnimation } from '../../LandingPage'
import { LandingPagePrimaryButton } from '../button/LandingPagePrimaryButton'

type LandingPageInscriptionCardProps = {
  content: string
  customCardWidth?: string
  fairness: number
  holders: number
  tick: string
  total: number
  // trending: number
  id: string
  key?: number
}

const LandingPageInscriptionCard = ({
  content,
  customCardWidth = 'w-[272px]',
  fairness,
  holders,
  total,
  tick,
}: // trending
LandingPageInscriptionCardProps) => {
  const navigate = useNavigate()
  const { address, connected } = useWalletContext()

  const getColor = (total: number) => {
    if (total < 20) {
      return '#EA3943'
    } else if (total < 40) {
      return '#EA8C01'
    } else if (total < 60) {
      return '#F3D42F'
    } else if (total < 80) {
      return '#93D900'
    } else {
      return '#14C784'
    }
  }

  const handleClickDrc20Tick = () => {
    window.open(`https://doge-labs.com/#/drc20/${tick}`, '_blank')
  }

  const handleClickMintButton = () => {
    if (connected) {
      navigate(`/service/inscribe/${tick}?walletAddress=${address}`)
    } else {
      navigate(`/service/inscribe/${tick}`)
    }
  }

  return (
    <div className={`flex-col  ${customCardWidth ?? ''} md:w-[340px] ml-[12px] mt-2 mb-[20px] ${cardHoverAnimation}`}>
      <div className=" w-[98%] mx-auto my-0 mb-[24px]">
        <div className="flex justify-between items-center">
          <span className="inline-block">Total</span>
          <span className="font-bold text-[#FFAE42]">{(Math.floor(100 * total) / 100).toFixed(2)}%</span>
        </div>
        <Progress percent={Number(total.toFixed(2))} strokeColor="#FFAE42" showInfo={false} />
      </div>

      <div className="h-[440px] rounded-[15px] flex-col overflow-hidden shadow-[0px_6px_16px_0px_#0000001A]">
        <div className="h-3/5 w-full bg-[black]">
          <div className="h-full flex justify-start ml-[10%] items-center text-left text-[#fff]">
            <span className="text-lg select-none" style={{ whiteSpace: 'pre-line' }}>
              {content}
            </span>
          </div>
        </div>
        <div className="h-2/5 w-full p-[24px] grid grid-cols-[repeat(3,1fr)] grid-rows-[repeat(2,1fr)] gap-x-3 gap-y-3">
          <div className="flex flex-col text-left font-600 text-[#000]">
            <span className="inline-block text-xs">Holders</span>
            <span className="inline-block text-lg">{holders.toLocaleString('en-US')}</span>
          </div>
          <div className="flex flex-col">
            {/* <div className="flex items-center text-[#000] font-600">
              <span className="inline-block text-xs mr-[8px]">Trending</span>
              <Tooltip
                title="When the value is closer to 0, there is only little demand for the respective token within the selected time frame.
                When the value is closer to 100, there is very high demand on the respective token within the selected time frame.
                Doge Labs derives that value from the total number of mint transactions within the selected time frame."
              >
                <MdInfoOutline size={16} color={'#FFAE42'} />
              </Tooltip>
            </div>
            <div className="flex items-center text-[#000]">
              <span className="inline-block text-lg text-left mr-[8px]">
                <span className="font-600">{trending}</span>/100
              </span>
              <div className="h-16px w-16px rounded-[30px]" style={{ backgroundColor: getColor(trending) }} />
            </div> */}
          </div>
          <div className="flex flex-col">
            <div className="flex items-center text-[#000] font-600">
              <span className="inline-block text-xs mr-[8px]">Fairness</span>
              <Tooltip
                title="When the value is closer to 0, very few mint transactions are needed in order to mint the total supply.
                When the value is closer to 100, many mint transactions are needed in order to mint the total supply.
                Doge Labs derives that value from the total number of mint transactions needed in order to mint the total supply (total supply / limit per mint)."
              >
                <MdInfoOutline size={16} color={'#FFAE42'} />
              </Tooltip>
            </div>
            <div className="flex items-center text-[#000]">
              <span className="inline-block text-lg text-left mr-[8px]">
                <span className="font-600">{fairness}</span>/100
              </span>
              <div className="h-16px w-16px rounded-[30px]" style={{ backgroundColor: getColor(fairness) }} />
            </div>
          </div>
          <div className="flex justify-start items-center" onClick={handleClickDrc20Tick}>
            <img
              className="block w-12 h-12 object-cover rounded-[15px] mr-[16px]"
              src={`https://drc-20-icons.dogeord.io/${tick.toLowerCase()}.png`}
              alt="Drc20"
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
                (e.currentTarget.src = '/ticks/noicon.png')
              }
            />
            <span className="inline-block leading-[48px] font-bold font-lg">{tick}</span>
          </div>
          <div />

          <LandingPagePrimaryButton
            onClick={handleClickMintButton}
            styles={{ width: '100%', height: '42px', margin: 'auto' }}
          >
            Mint
          </LandingPagePrimaryButton>
        </div>
      </div>
    </div>
  )
}

export { LandingPageInscriptionCard }
export type { LandingPageInscriptionCardProps }

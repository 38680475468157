import Sentry from '@/main'

export const formatDate = (dateString?: string) => {
  if (!dateString) {
    return ''
  }

  let date = null
  let dateFormatted = ''
  try {
    date = new Date(dateString)

    dateFormatted = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
      timeZone: 'UTC',
    }).format(date)
  } catch (error) {
    console.log('try formatDate again with Safari fix')
    try {
      // fix for safari (https://github.com/w3c/respec/issues/1357)
      date = new Date(dateString.replace(/-/g, '/'))

      dateFormatted = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: 'UTC',
      }).format(date)
    } catch (errorSafariFix) {
      console.error('error still exists', error, errorSafariFix)
      Sentry.captureException(error, {
        extra: {
          file: 'formatDate.ts',
          function: 'formatDate()',
          dateString: dateString,
        },
      })
    }
  }

  return dateFormatted
}

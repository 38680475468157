import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md'
import { register } from 'swiper/element/bundle'
import { Pagination } from 'swiper/modules'
import { Swiper } from 'swiper/react'
import { useMediaQuery } from 'usehooks-ts'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './styles.css'

register()

type SwiperSlider = {
  children: React.ReactNode
  spaceBetween?: number
  isLoop?: boolean
  slidesPerView?: number | 'auto'
  shouldShowRightButton?: boolean
  shouldShowNavigationDots?: boolean
}

export const SwiperSlider = ({
  children,
  spaceBetween = 32,
  isLoop = true,
  slidesPerView = 'auto',
  shouldShowRightButton = true,
  shouldShowNavigationDots = false,
}: SwiperSlider) => {
  const isTablet = useMediaQuery('(min-width: 768px)')
  const [swiper, setSwiper] = useState<any>(null)

  const toPrevious = () => {
    swiper?.slidePrev()
  }

  const toNext = () => {
    swiper?.slideNext()
  }

  return (
    <div className="swiper-wrapper" style={{ marginBottom: shouldShowNavigationDots ? 0 : '24px' }}>
      {isTablet && (
        <button onClick={toPrevious} className="mx-[12px]">
          <MdKeyboardArrowLeft size={32} />
        </button>
      )}
      <Swiper
        onSwiper={(s: any) => {
          setSwiper(s)
        }}
        loop={isLoop}
        slidesPerView={slidesPerView}
        centeredSlides={false}
        spaceBetween={spaceBetween}
        mousewheel={{ forceToAxis: true }}
        pagination={
          shouldShowNavigationDots && {
            clickable: true,
          }
        }
        modules={shouldShowNavigationDots ? [Pagination] : undefined}
      >
        {children}
      </Swiper>

      {shouldShowRightButton && (
        <button onClick={toNext} className="ml-[0px] md:ml-[12px]">
          <MdKeyboardArrowRight size={32} />
        </button>
      )}
    </div>
  )
}

import { RevealedOverview } from '@/components/doginalpacks/reveal/revealedOverview/revealedOverview'
import { RevealingOverview } from '@/components/doginalpacks/reveal/revealingOverview/revealingOverview'
import { RevealOverview } from '@/components/doginalpacks/reveal/revealOverview/revealOverview'
import { useRevealData } from '@/hooks/useRevealData'

export function RevealContent() {
  const { isRevealing, revealedLabradoges } = useRevealData()
  // return revealedLabradoges ? <RevealedOverview /> : isRevealing ? <RevealingOverview /> : <RevealOverview />
  return revealedLabradoges ? (
    <RevealedOverview />
  ) : isRevealing ? (
    <RevealOverview navigateBack={true} />
  ) : (
    <RevealOverview />
  )
}

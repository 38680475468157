import { Image } from 'antd'
import { useCallback, useState } from 'react'
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from 'react-icons/md'

import { getDoginalsFromCollection, getFeaturedDoginalCollections } from '@/api'
import { DoginalsCollection } from '@/types/dogeNft'
import { LANDING_PAGE_DOGE_NFT_ITEMS_PER_PAGE } from '@/utils/constants'

import LoadingSpinnerIcon from '../LoadingSpinnerIcon'
import DoginalTrendingTableRow from './DoginalTrendingTableRow'
import { SortByTypes } from './Trending'

export const featuredCollectionsList = ['']

type DoginalTrendingTableProps = {
  collections: DoginalsCollection[]
  selectedSortType: SortByTypes
  dogePrice: number
  isSorting: boolean
  setIsSorting: (isSorting: boolean) => void
  currentDogeNftPage: number
  totalDogeNftPages: number
  setCurrentDogeNftPage: React.Dispatch<React.SetStateAction<number>>
}

const DoginalTrendingTable = ({
  collections,
  selectedSortType,
  dogePrice,
  isSorting,
  setIsSorting,
  currentDogeNftPage,
  totalDogeNftPages,
  setCurrentDogeNftPage,
}: DoginalTrendingTableProps) => {
  const [featuredCollections, setFeaturedCollections] = useState<any[]>([])

  const fetchFeaturedCollections = useCallback(async () => {
    const { res } = await getFeaturedDoginalCollections(0, 5)
    const doginalsList: any = res?.data?.collections || []

    // get floor price for collections with floor price = 0
    const zeroFloorPriceCollections = doginalsList.filter((collection: any) => collection.floorPrice === 0)
    const collectionsWithFloorPrice = await Promise.all(
      zeroFloorPriceCollections.map(async (collection: any) => {
        const { doginals } = await getDoginalsFromCollection(collection.symbol, 1, 0, 'asc')
        if (!doginals || doginals.length < 1) {
          return { symbol: collection.symbol, floorPrice: 0 }
        }
        const floorPrice = doginals[0].price
        return { symbol: collection.symbol, floorPrice }
      })
    )

    const enhancedCollections = doginalsList.map((collection: any) => {
      const floorPrice = collectionsWithFloorPrice.find((c) => c.symbol === collection.symbol)?.floorPrice
      return floorPrice ? { ...collection, floorPrice } : collection
    })

    setFeaturedCollections(enhancedCollections)
  }, [])

  useEffect(() => {
    fetchFeaturedCollections()
  }, [fetchFeaturedCollections])

  const handlePageChange = useCallback(
    (page: number) => {
      setIsSorting(true)
      setCurrentDogeNftPage(page)
      setIsSorting(false)
    },
    [setCurrentDogeNftPage, setIsSorting]
  )

  return (
    <div className="flex flex-col overflow-y-hidden overflox-x-scroll">
      {(() => {
        if (isSorting) {
          return (
            <div className="flex flex-col mt-10 justify-center items-center">
              <h2 className="text-[2rem] font-bold mb-2">Sorting table...</h2>
              <div className="h-[3rem] w-[3rem] text-gray-600 fill-amber-500">
                <LoadingSpinnerIcon />
              </div>
            </div>
          )
        } else {
          return (
            <table className="w-full min-w-[1000px]" id="doginal-table">
              <thead className="border-b-2 border-[#EFF2F5] w-full">
                <tr>
                  <th
                    colSpan={1}
                    className="font-normal py-5 text-left sticky left-0 marketplace-table-cell-shadow-right xxs:bg-similar-to-bg-color tableScrollBreakpoint:bg-inherit z-10"
                  >
                    <span className="pr-4 w-8 text-center">#</span>
                    <span className="pr-1">Collection</span>
                  </th>
                  <th colSpan={1} className="font-normal py-5 text-left pl-5">
                    Floor Price
                  </th>
                  <th colSpan={1} className="font-normal py-5 text-left">
                    Volume
                  </th>
                  <th colSpan={1} className="font-normal py-5 text-left">
                    Listed
                  </th>
                  <th colSpan={1} className="font-normal py-5 text-left">
                    Sales
                  </th>
                  <th colSpan={1} className="font-normal py-5 text-left">
                    Holders
                  </th>
                  <th colSpan={1} className="font-normal py-5 text-left">
                    Supply
                  </th>
                </tr>
              </thead>
              <tbody
                className={`[&>tr>*:nth-child(1)]:w-20%] [&>tr>*:nth-child(2)]:w-[15%] [&>tr>*:nth-child(3)]:w-[18%] [&>tr>*:nth-child(4)]:w-[15%] [&>tr>*:nth-child(5)]:w-[15%] [&>tr>*:nth-child(6)]:w-[15%]`}
              >
                {featuredCollections &&
                  featuredCollections.map((collection, index) => (
                    <DoginalTrendingTableRow
                      key={`featuredCollection-${collection.symbol}`}
                      index={<>🔥</>}
                      imageURI={collection.imageURI}
                      name={collection.name}
                      symbol={collection.symbol}
                      floorPrice={collection.floorPrice}
                      volume={collection.totalVolume}
                      sales={collection.sales}
                      holders={collection.holders}
                      supply={collection.supply}
                      dogePrice={dogePrice}
                      totalListed={collection.totalListed}
                      visible={true}
                      isDogepadCollection={collection.dogepadCollection || false}
                      marketplaceVerified={collection.marketplaceVerified || false}
                    />
                  ))}
                {collections &&
                  collections
                    .filter(
                      (collection) => !featuredCollections.some((featured) => featured.symbol === collection.symbol)
                    )
                    .map((collection, index) => {
                      return (
                        <DoginalTrendingTableRow
                          key={
                            currentDogeNftPage === 1
                              ? `collections-${collection.symbol}-${index}`
                              : `collections-${collection.symbol}-${
                                  (currentDogeNftPage - 1) * LANDING_PAGE_DOGE_NFT_ITEMS_PER_PAGE + index
                                }`
                          }
                          index={
                            currentDogeNftPage === 1
                              ? index + 1
                              : (currentDogeNftPage - 1) * LANDING_PAGE_DOGE_NFT_ITEMS_PER_PAGE + index + 1
                          }
                          imageURI={collection.imageURI}
                          name={collection.name}
                          symbol={collection.symbol}
                          floorPrice={collection.floorPrice}
                          volume={collection.totalVolume}
                          sales={collection.sales}
                          holders={collection.holders}
                          supply={collection.supply}
                          dogePrice={dogePrice}
                          totalListed={collection.totalListed}
                          visible={collection.visible}
                          isDogepadCollection={collection.dogepadCollection || false}
                          marketplaceVerified={collection.marketplaceVerified || false}
                        />
                      )
                    })}
              </tbody>
            </table>
          )
        }
      })()}

      <div className="flex mt-8 w-full justify-center">
        <nav className="flex items-center justify-between">
          <div className="flex items-center justify-between gap-2">
            <div>
              <button
                onClick={() => handlePageChange(1)}
                disabled={currentDogeNftPage === 1}
                className="px-2 py-2 text-base font-medium text-gray-500 border border-r-0 border-gray-300 rounded-l-md cursor-pointer hover:bg-account-page-default"
                style={{ backgroundColor: currentDogeNftPage === 1 ? '#EFF2F5' : '' }}
              >
                <MdKeyboardDoubleArrowLeft />
              </button>
              <button
                onClick={() => handlePageChange(currentDogeNftPage - 1)}
                disabled={currentDogeNftPage === 1}
                className="px-2 py-2 text-base font-medium text-gray-500 border border-gray-300 cursor-pointer hover:bg-account-page-default"
                style={{ backgroundColor: currentDogeNftPage === 1 ? '#EFF2F5' : '' }}
              >
                <MdKeyboardArrowLeft />
              </button>
            </div>
            <div>
              <p className="text-sm text-gray-500">
                Page {currentDogeNftPage} of {totalDogeNftPages}
              </p>
            </div>
            <div>
              <button
                onClick={() => handlePageChange(currentDogeNftPage + 1)}
                disabled={currentDogeNftPage === totalDogeNftPages}
                className="px-2 py-2 text-base font-medium text-gray-500 border border-gray-300 cursor-pointer hover:bg-account-page-default"
                style={{ backgroundColor: currentDogeNftPage === totalDogeNftPages ? '#EFF2F5' : '' }}
              >
                <MdKeyboardArrowRight />
              </button>
              <button
                onClick={() => handlePageChange(totalDogeNftPages)}
                disabled={currentDogeNftPage === totalDogeNftPages}
                className="px-2 py-2 text-base font-medium text-gray-500 border border-l-0 border-gray-300 rounded-r-md cursor-pointer hover:bg-account-page-default"
                style={{ backgroundColor: currentDogeNftPage === totalDogeNftPages ? '#EFF2F5' : '' }}
              >
                <span className="flex">
                  <MdKeyboardDoubleArrowRight />
                </span>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default DoginalTrendingTable

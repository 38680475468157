import uniqBy from 'lodash.uniqby'
import { useMediaQuery } from 'usehooks-ts'

import { getDoginalsFromCollection, getDoginalTableItems, getDrc20TableItems } from '@/api'
import { DisplayType } from '@/types/common'
import { DoginalsCollection } from '@/types/dogeNft'
import { Drc20Data } from '@/types/drc20'
import { SortingTimeFrameWithAll } from '@/types/sorting'

import { LandingPageDropdown } from '../components/dropdown/LandingPageDropdown'
import { LandingPageSortingPill } from '../components/sorting/LandingPageSortingPill'
import { LandingPageTable as Table } from '../components/table/Table'

type LandingPageTableProps = {
  dogePriceInUSD: number
}

enum SortingType {
  Top = 'Top',
  Trending = 'Trending',
}

const LandingPageTable = ({ dogePriceInUSD }: LandingPageTableProps) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)')
  const [drc20List, setDrc20List] = useState<Array<Drc20Data>>([])
  const [doginalList, setDoginalList] = useState<Array<DoginalsCollection>>([])
  const [sortingType, setSortingType] = useState<SortingType>(SortingType.Top)
  const [sortingTimeFrame, setSortingTimeFrame] = useState<SortingTimeFrameWithAll>(SortingTimeFrameWithAll.SevenDays)

  const getSortParam = (sortingType: SortingType) => {
    switch (sortingType) {
      case SortingType.Top:
        return 'top'
      case SortingType.Trending:
        return 'trending'
    }
  }

  const getTimeframe = (sortingTimeFrame: SortingTimeFrameWithAll) => {
    switch (sortingTimeFrame) {
      case SortingTimeFrameWithAll.OneHour:
        return '1h'
      case SortingTimeFrameWithAll.SixHours:
        return '6h'
      case SortingTimeFrameWithAll.TwentyFourHours:
        return '24h'
      case SortingTimeFrameWithAll.SevenDays:
        return '7d'
      case SortingTimeFrameWithAll.All:
        return 'all'
    }
  }

  const getNextBiggerTimeframe = (sortingTimeFrame: SortingTimeFrameWithAll) => {
    const allTimeFrames = Object.values(SortingTimeFrameWithAll)
    const currentTimeFrameIndex = allTimeFrames.indexOf(sortingTimeFrame)
    return currentTimeFrameIndex === allTimeFrames.length - 1
      ? sortingTimeFrame
      : allTimeFrames[currentTimeFrameIndex + 1]
  }

  const biggerVolumesFirst = (a: any, b: any) => parseFloat(b.activityVolume) - parseFloat(a.activityVolume)

  useEffect(() => {
    const timeframe = getTimeframe(sortingTimeFrame)
    const sortParam = getSortParam(sortingType)

    const fetchDRC20Data = async (timeframe: string, sortParam: 'top' | 'trending') => {
      try {
        const { res } = await getDrc20TableItems(0, 3, timeframe, sortParam)
        let list: Drc20Data[] = res?.data.list

        //  if less than five, fetch then next 10 as fill up
        // if still not enough, fetch from next timeframe
        let newTimeFrame = sortingTimeFrame
        while (list.length < 5) {
          newTimeFrame = getNextBiggerTimeframe(newTimeFrame)
          const { res: additionalFetchedResult } = await getDrc20TableItems(0, 10, newTimeFrame, sortParam)
          // set volume to 0 for next timeframe collections
          const fillupDrc20s = additionalFetchedResult?.data.list.map((drc20: any) => ({
            ...drc20,
            activityVolume: 0,
          }))
          list = uniqBy([...list, ...fillupDrc20s], 'tick').slice(0, 5)
        }
        setDrc20List(sortParam === 'top' ? list.sort(biggerVolumesFirst) : list)
      } catch (e) {
        console.log(e)
      }
    }

    const fetchDoginalData = async (timeframe: string, sortParam: 'top' | 'trending') => {
      try {
        const { res } = await getDoginalTableItems(0, 5, timeframe, sortParam)
        let collections: DoginalsCollection[] = res?.data.collections

        // if less than five, fetch then next as fill up
        // if still not enough, fetch from next timeframe
        let newTimeFrame = sortingTimeFrame
        while (collections.length < 5) {
          newTimeFrame = getNextBiggerTimeframe(newTimeFrame)
          const { res: additionalFetchedResult } = await getDoginalTableItems(0, 10, newTimeFrame, sortParam)
          // set volume to 0 for next timeframe collections
          const fillupCollections = additionalFetchedResult?.data.collections.map((collection: any) => ({
            ...collection,
            activityVolume: 0,
          }))
          collections = uniqBy([...collections, ...fillupCollections], 'symbol').slice(0, 5)
        }

        // get floor price for collections with floor price = 0
        const zeroFloorPriceCollections = collections?.filter(
          (collection: any) => collection.floorPrice === 0 || collection.floorPrice > 9000000000000000
        )

        const collectionsWithFloorPrice = await Promise.all(
          zeroFloorPriceCollections.map(async (collection: any) => {
            const { doginals } = await getDoginalsFromCollection(collection.symbol, 1, 0, 'asc')
            if (!doginals || doginals.length < 1) {
              return { symbol: collection.symbol, floorPrice: 0 }
            }
            const floorPrice = doginals[0].price
            return { symbol: collection.symbol, floorPrice }
          })
        )

        const enhancedCollections = collections.map((collectionItem: any) => {
          const hasFloorPrice = collectionsWithFloorPrice.find((c) => c.symbol === collectionItem.symbol)
          return hasFloorPrice ? { ...collectionItem, floorPrice: hasFloorPrice.floorPrice } : collectionItem
        })

        setDoginalList(sortParam === 'top' ? enhancedCollections.sort(biggerVolumesFirst) : enhancedCollections)
      } catch (e) {
        console.log(e)
      }
    }

    fetchDRC20Data(timeframe, sortParam)
    fetchDoginalData(timeframe, sortParam)
  }, [sortingTimeFrame, sortingType])

  return (
    <>
      <div className="w-full flex flex-wrap gap-[8px] justify-between items-center mb-[48px] mt-[24px]">
        <LandingPageSortingPill
          options={Object.values(SortingType)}
          defaultValue={sortingType}
          onChange={(x) => {
            setSortingType(x as SortingType)
          }}
        />
        <div className="flex flex-wrap gap-[8px] items-center">
          {isDesktop ? (
            <LandingPageSortingPill
              options={Object.values(SortingTimeFrameWithAll)}
              defaultValue={sortingTimeFrame}
              onChange={(x) => {
                setSortingTimeFrame(x as SortingTimeFrameWithAll)
              }}
            />
          ) : (
            <LandingPageDropdown
              items={Object.values(SortingTimeFrameWithAll)}
              onClick={(item) => setSortingTimeFrame(item as SortingTimeFrameWithAll)}
            />
          )}
        </div>
      </div>

      <div className="w-full max-w[1280px] gap-[12px] flex justify-between flex-wrap">
        <Table
          type={DisplayType.DOGINALS}
          doginalList={doginalList}
          headTitles={{ headerOne: 'Rank', headerTwo: 'Collection', headerThree: 'Floor Price', headerFour: 'Volume' }}
          dogePriceInUSD={dogePriceInUSD}
          viewAllURL={`https://doge-labs.com/`}
        />
        <Table
          type={DisplayType.DRC20}
          drc20List={drc20List}
          headTitles={{
            headerOne: 'Rank',
            headerTwo: 'drc-20',
            headerThree: 'Price',
            headerFour: 'Volume',
            headerFive: 'MCap',
          }}
          dogePriceInUSD={dogePriceInUSD}
          viewAllURL={`https://doge-labs.com/`}
        />
      </div>
    </>
  )
}

export { LandingPageTable }

const numberToLocaleString = (value: number) => {
  let maximumFractionDigits = 2
  if (Math.abs(value) < 0.000000001) {
    maximumFractionDigits = 12
  } else if (Math.abs(value) < 0.00000001) {
    maximumFractionDigits = 11
  } else if (Math.abs(value) < 0.0000001) {
    maximumFractionDigits = 10
  } else if (Math.abs(value) < 0.000001) {
    maximumFractionDigits = 9
  } else if (Math.abs(value) < 0.00001) {
    maximumFractionDigits = 8
  } else if (Math.abs(value) < 0.0001) {
    maximumFractionDigits = 7
  } else if (Math.abs(value) < 0.001) {
    maximumFractionDigits = 6
  } else if (Math.abs(value) < 0.01) {
    maximumFractionDigits = 5
  } else if (Math.abs(value) < 0.1) {
    maximumFractionDigits = 4
  }

  return value.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits,
    notation: 'compact',
    compactDisplay: 'short',
  })
}

export { numberToLocaleString }

import { DownOutlined, UpOutlined } from '@ant-design/icons'
import { Button } from 'antd'

type LandingPageDropdownProps = {
  items: string[]
  onClick?: (x: string) => void
  style?: React.CSSProperties
}

const LandingPageDropdown = ({ items, onClick, style }: LandingPageDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<string>(items[items.length - 1])

  const handleClick = (item: string) => {
    setSelectedOption(item)
    setIsOpen(false)
    onClick && onClick(item)
  }

  return (
    <div className="relative z-[1]">
      <Button
        className="w-[88px] md:w-[160px] h-[48px] bg-[#F2F2F2] rounded-[8px] font-bold flex justify-between items-center border-none text-[#262626] font-bold hover:bg-[#F6F6F6] hover:text-[#262626] hover:border-none focus:bg-[#F6F6F6] focus:text-[#262626]"
        onClick={() => setIsOpen(!isOpen)}
        style={{ ...style }}
      >
        <span className="inline-block">{selectedOption}</span>
        <span className="inline-block">{isOpen ? <DownOutlined /> : <UpOutlined />}</span>
      </Button>
      {isOpen && (
        <div
          className="flex flex-col rounded-[8px] font-bold w-[88px] md:w-[160px] h-fit absolute top-[48px] bg-[#F6F6F6] cursor-pointer"
          style={{ ...style }}
        >
          {items.map((item, idx) => (
            <div className="px-[8px] py-[8px]" key={idx} onClick={(_) => handleClick(item)}>
              <div className="bg-white rounded-[4px] px-[8px] py-[8px]">{item}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export { LandingPageDropdown }
